export { getLayers, getMaps, getLayerInfos, getMapInfos }
import maps from './data/maps.json'
//import mortarTypes from './mortars.json'
//import factions from './data/factions.json'
import layers from './data/layers.json'
//import setups from './data/units.json'

function getMaps(){
    let ret = {}

    for(const map in maps){
        ret[maps[map].name] = maps[map].title
    }
    
    //console.log(ret);
    
    return ret
}

function getLayers(map){
    let ret = []

    for (const layer in layers) {
        if (layers[layer].map == map) {
            ret.push(layers[layer].gamemode + " v" + layers[layer].version)
        }
    }

    console.log(ret);

    return ret
}

function getLayerInfos(layer){
    return layers[layer]
}

function getMapInfos(map){
    return maps[map]
}