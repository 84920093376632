<template>
    <div id="bottom-menu">
        <button @click="toggleLeftMenu" :class="{active: this.toggleLeftMenuActive}">Toggle mortar infos</button>
        <div>Mouse coords. : {{ this.mouseCoords.letter }}{{ this.mouseCoords.digit }} - {{ this.mouseCoords.firstDec }} - {{ this.mouseCoords.secDec }}</div>
        <button @click="toggleRightMenu" :class="{active: this.toggleRightMenuActive}">Toggle layer infos</button>
    </div>
</template>

<script>
    export default{
        data(){
            return{

                /**
                 * Live mouse coordinates over the map
                 * @type {Object}
                 */
                mouseCoords:{letter: "A", digit: 1, firstDec: 1, secDec: 1},

                /**
                 * Used to add CSS 'active' class to the button
                 * @type {boolean}
                 */
                toggleLeftMenuActive: false,

                /**
                 * Used to add CSS 'active' class to the button
                 * @type {boolean}
                 */
                toggleRightMenuActive: false
            }
        },
        methods:{

            /**
             * Handles the button click to toggle left menu
             */
            toggleLeftMenu(){
                this.$emit('toggleLeftMenu')
                this.toggleLeftMenuActive = !this.toggleLeftMenuActive
            },

            /**
             * Handles the button click to toggle left menu
             */
            toggleRightMenu(){
                this.$emit('toggleRightMenu')
                this.toggleRightMenuActive = !this.toggleRightMenuActive
            }
        }
    }
</script>

<style scoped>

#bottom-menu{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 50;
  color: white;
  width: 35vw;
  padding: 0.5rem;
  background-color: rgba(20, 20, 20, 0.6);
  backdrop-filter: blur(20px);
  border-top: solid 3px #908F8E;
  border-left: solid 3px #908F8E;
  border-right: solid 3px #908F8E;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

</style>