<template>
    <div id="top-menu">
        <div>
            <div style="flex: 1 1; display: flex; flex-direction: row; justify-content: end; align-items: center; margin-right: 1vh;">
                <label style="font-size: 2vh; margin-right: 1vh;" for="select-map">Map : </label>
                <select name="select-map" id="select-map" ref="selectMap" v-model="this.selectedMap" @change="changeSelectedMap" >
                    <option v-for="(map, index) in maps" :key="index" :value="index">{{ map }}</option>
                </select>
            </div>
            <div style="flex: 1 1; display: flex; flex-direction: row; justify-content: start; align-items: center; margin-left: 1vh;">
                <label style="font-size: 2vh; margin-right: 1vh;" for="select-layer">Layer : </label>
                <select name="select-layer" id="select-layer" ref="selectLayer" v-model="this.selectedLayer" @change="changeSelectedLayer">
                    <option v-for="(layer) in layers" :key="layer" :value="layer">{{ layer }}</option>
                </select>
            </div>
        </div>
        <div>
            <div style="font-size: 2vh;">Map settings :</div>
            <div><input type="checkbox" name="showGrid" v-model="showGrid"><label for="showGrid">Grid</label></div>
            <div><input type="checkbox" name="showObjectives" v-model="showObjectives"><label for="showObjectives">Objectives</label></div>
            <div><input type="checkbox" name="showMortar" v-model="showMortar"><label for="showMortar">Mortar</label></div>
        </div>
    </div>
</template>

<script>

export default{
    data(){
        return{

            /**
             * Currently selected map, used in the selectMap select tag
             * @type {String}
             */
            selectedMap: '',

            /**
             * Currently selected layer, used the selectLayer select tag
             * @type {String}
             */
            selectedLayer: '',

            /**
             * Variable used to show or hide grid on the map
             * @type {Boolean}
             */
             showGrid: true,
            
            /**
             * Variable used to show or hide objectives on the map
             * @type {Boolean}
             */
            showObjectives: true,
            
            /**
             * Variable used to show or hide mortar on the map
             * @type {Boolean}
             */
            showMortar: true
        }
    },
    mounted(){
        // Visual purpose on select tags
        this.selectedMap = Object.keys(this.maps)[0]
        this.selectedLayer = this.layers[0]
    },
    methods:{
        
        /**
         * Notify parent to change the selected map 
         */
        changeSelectedMap(){
            this.$emit('changeMap', this.selectedMap)
        },
        
        /**
         * Notify parent to change the selected layer
         */
        changeSelectedLayer(){
            this.$emit('changeLayer', this.selectedLayer)
        }
    },
    props:{

        /**
         * Contains maps names
         * @type {Object}
         */
        maps:{
            type: Object,
            required: true
        },

        /**
         * Contains layers names of the selected map
         * @type {Array}
         */
        layers:{
            type: Array,
            required: true
        }
    },
    watch: {
        
        /**
         * On maps prop update the select tags are reset and 1st map is selected by default
         */
        maps(){
            this.selectedMap = Object.keys(this.maps)[0]
            console.log("Selected map :");
            console.log(this.selectedMap);
        },
        
        /**
         * On layers prop update the select tags are reset and 1st map is selected by default
         */
        layers(){
            this.selectedLayer = this.layers[0]
        },
        
        /**
         * On variable update, notify parent to toggle the grid display on map
         */
         showGrid(){
            this.$emit('toggleGrid')
        },
        
        /**
         * On variable update, notify parent to toggle the objectives display on map
         */
        showObjectives(){
            this.$emit('toggleObjectives')
        },
        
        /**
         * On variable update, notify parent to toggle the mortar display on map
         */
        showMortar(){
            this.$emit('toggleMortar')
        }
    }
}
</script>

<style scoped>

#top-menu{
    color: white;
    width: 35vw;
    padding: 0.5rem;
    background-color: rgba(20, 20, 20, 0.6);
    backdrop-filter: blur(20px);
    border-bottom: solid 3px #908F8E;
    border-left: solid 3px #908F8E;
    border-right: solid 3px #908F8E;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 50;
}

#top-menu select{
  font-size: 1.5vh;
  width: 50%;
}

#top-menu>div {
    width: 100%;
    margin: 0.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#top-menu>div>button {
    flex: 1 1 0px;
    margin-left: 0.5vw;
    padding: 1vh;
}

</style>