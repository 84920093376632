<template>
    <div id="mobile-right-menu">
        <button id="mobile-right-menu-close-button" @click="closeMobileRightMenu">X</button>
        <div id="mobile-right-content">
            <div style="font-size: 4vh; flex: 0 1;">Layer infos</div>
            <div id="mobile-right-select" style="flex: 0 1;">
                <select name="select-map" id="select-map" ref="selectMap" v-model="this.selectedMap" @change="changeSelectedMap" >
                    <option v-for="(map, index) in maps" :key="index" :value="index">{{ map }}</option>
                </select>
                <select name="select-layer" id="select-layer" ref="selectLayer" style="margin-top: 2vw;" v-model="this.selectedLayer" @change="changeSelectedLayer">
                    <option v-for="(layer) in layers" :key="layer" :value="layer">{{ layer }}</option>
                </select>
            </div>
            <div id="mobile-right-layer">
                <div>
                    <div v-if="this.layerInfos.layerName.includes('Territory')">{{ this.layerInfos.nbPoints }} hexs</div>
                    <div v-else-if="this.layerInfos.layerName.includes('Insurgency') || this.layerInfos.layerName.includes('Destruction')">{{ this.layerInfos.nbPoints }} caches</div>        
                    <div v-else>{{ this.layerInfos.nbPoints }} flags</div>        
                    <div v-if="isLanes()"><a class="squadlanes-link" :href="getSquadLanesURL()" target="_blank">SquadLanes link</a></div>
                </div>
                <div id="mobile-right-blufor" class="mobile-right-expandable">
                    <div class="flexbox-row" @click="toggleBlufor">
                        <div class="faction-header"><img class="faction-flag" :src="this.factionIcon[0]" :alt="this.factions[this.layerInfos.blufor.factionName] + 'flag'"> {{ this.factions[this.layerInfos.blufor.factionName] }}</div>
                        <div class="dropdown-btn" v-bind:class="{'dropdown-active': showBlufor}">&#709;</div>
                    </div>
                    <Transition name="dropdown">
                        <div v-if="showBlufor">
                            <div>{{ this.layerInfos.blufor.tickets }} <img style="height: 2.5vh; width: auto;" src="/utils/right/ticket.png" alt="tickets"></div>
                            <div id="mobile-right-blufor-vehicles" class="div-table" style="font-size: 2vh;">
                                <div class="thead">
                                    <div class="data-cell" style="flex-basis: 80%;">Name</div>
                                    <div class="data-cell" style="flex-basis: 20%;">Qty</div>
                                    <div class="data-cell" style="flex-basis: 20%;">Resp.</div>
                                    <div class="data-cell" style="flex-basis: 20%;">Delay</div>
                                </div>
                                <div class="tbody">
                                    <div class="tr" v-for="vhl in this.layerInfos.blufor.vehicles" :key="vhl">
                                        <div class="data-cell" style="flex-basis: 80%; justify-content: flex-start;"><img style="height: 3vh; width: auto;" :src="this.getVehicleIcon(vhl.icon)" alt=""> {{ vhl.name }}</div>
                                        <div class="data-cell" style="flex-basis: 20%;">{{ vhl.count }}</div>
                                        <div v-if="vhl.respawnTime != 0" class="data-cell" style="flex-basis: 20%;">{{ vhl.respawnTime }}</div>
                                        <div v-else style="flex-basis: 20%;">No resp.</div>
                                        <div class="data-cell" style="flex-basis: 20%;">{{ vhl.delay }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div id="mobile-right-redfor" class="mobile-right-expandable">
                    <div class="flexbox-row" @click="toggleRedfor">
                        <div class="faction-header"><img class="faction-flag" :src="this.factionIcon[1]" :alt="this.factions[this.layerInfos.redfor.factionName] + 'flag'"> {{ this.factions[this.layerInfos.redfor.factionName] }}</div>
                        <div class="dropdown-btn" v-bind:class="{'dropdown-active': showRedfor}">&#709;</div>
                    </div>
                    <Transition name="dropdown">
                        <div v-if="showRedfor">
                            <div>{{ this.layerInfos.redfor.tickets }} <img style="height: 2.5vh; width: auto;" src="/utils/right/ticket.png" alt="tickets"></div>
                            <div id="mobile-right-redfor-vehicles" class="div-table" style="font-size: 2vh;">
                                <div class="thead">
                                    <div class="data-cell" style="flex-basis: 80%;">Name</div>
                                    <div class="data-cell" style="flex-basis: 20%;">Qty</div>
                                    <div class="data-cell" style="flex-basis: 20%;">Resp.</div>
                                    <div class="data-cell" style="flex-basis: 20%;">Delay</div>
                                </div>
                                <div class="tbody">
                                    <div class="tr" v-for="vhl in this.layerInfos.redfor.vehicles" :key="vhl">
                                        <div class="data-cell" style="flex-basis: 80%; justify-content: flex-start;"><img style="height: 3vh; width: auto;" :src="this.getVehicleIcon(vhl.icon)" alt=""> {{ vhl.name }}</div>
                                        <div class="data-cell" style="flex-basis: 20%;">{{ vhl.count }}</div>
                                        <div v-if="vhl.respawnTime != 0" class="data-cell" style="flex-basis: 20%;">{{ vhl.respawnTime }}</div>
                                        <div v-else style="flex-basis: 20%;">No resp.</div>
                                        <div class="data-cell" style="flex-basis: 20%;">{{ vhl.delay }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{

            /**
             * Currently selected map, used in the selectMap select tag
             * @type {String}
             */
            selectedMap: '',

            /**
             * Currently selected layer, used the selectLayer select tag
             * @type {String}
             */
            selectedLayer: '',

            /**
             * Used to show or hide the blufor team component
             * @type {Boolean}
             */
            showBlufor: false,
            
            /**
             * Used to show or hide the redfor team component
             * @type {Boolean}
             */
            showRedfor: false,

            /**
             * Contains the translation from Squad's factions names to simpler names used on the app
             * @type {Object}
             */
            factions:{
                "United States Army": "USA",
                "Middle Eastern Alliance": "MEA",
                "Insurgent Forces": "INS",
                "Australian Defence Force": "ADF",
                "British Armed Forces": "BAF",
                "United States Marine Corps": "USMC",
                "Canadian Armed Forces": "CAF",
                "People's Liberation Army": "PLA",
                "Irregular Militia Forces": "MIL",
                "Russian Ground Forces": "RGF",
                "Russian Airborne Forces": "VDV",
                "PLA Navy Marine Corps": "PLANMC",
                "Turkish Land Forces": "TLF"
            },

            /**
             * Contains the translation from Squad's vehicles names to simpler names used on the app
             * @type {Object}
             */
            weakSpots:{
                "ASLAV":"aslav",
                "BMD-1M":"bmd1",
                "BMD-4M":"bmd4",
                "BMP-1":"bmp1",
                "BMP-1 ZU-23-2":"bmp1",
                "BMP-2":"bmp2",
                "BRDM-2":"brdm2",
                "BRDM-2 S8":"brdm2",
                "BRDM-2 Spandrel":"brdm2",
                "BTR-80":"btr80",
                "BTR-82A":"btr82",
                "BTR-D":"btrd",
                "BTR-D Kord":"btrd",
                "BTR-D PKM":"btrd",
                "BTR-DG Logistics":"btrd",
                "BTR-MDM":"btrmdm",
                "BTR-ZD":"btrd",
                "Coyote":"coyote",
                "FV107":"fv107",
                "FV4034":"fv4034",
                "FV432":"fv432",
                "FV432 RWS":"fv432",
                "FV510":"fv510",
                "FV510 UA":"fv510",
                "FV520 CTAS40":"fv520",
                "LAV 6":"lav6",
                "LAV III C6 RWS":"aslav",
                "LAV III M2 RWS":"aslav",
                "LAV-25":"aslav",
                "Leopard 2A6M CAN":"2a6",
                "M1126 CROWS M2":"stryker",
                "M1126 CROWS M240":"stryker",
                "M113A3 C6":"tlav",
                "M113A3 M2":"tlav",
                "M113A3 TLAV":"tlav",
                "M1A1":"m1a1",
                "M1A2":"m1a2",
                "M2A3":"m2a3",
                "MT-LB Logistics":"mtlb",
                "MT-LB PKT":"mtlb",
                "MT-LB VMK":"mtlb",
                "MT-LB ZU-23-2":"mtlb",
                "MT-LBM 6MA":"mtlb",
                "MT-LBM 6MA S8":"mtlb",
                "MT-LBM 6MB":"mtlb",
                "Sprut-SDM1":"sprut",
                "T-62":"t62",
                "T-72B3":"t72b3",
                "T-72S":"t72s",
                "TAPV C6":"tapv",
                "TAPV M2":"tapv",
                "ZBD04A":"zbd04",
                "ZBD05":"zbd05",
                "ZBD05 HJ73C":"zbd05",
                "ZBL08":"zbl08",
                "ZBL08 HJ73C":"zbl08",
                "ZSD05":"zsd05",
                "ZSD05 Logistics":"zsd05",
                "ZSL10":"zsl10",
                "ZTD05":"ztd05",
                "ZTZ99A":"ztz99",
                "ACV-15 MG3":"acv15",
                "ACV-15 M2":"acv15",
                "ACV-15 25mm":"acv15",
                "PARS III MG3 RWS":"pars",
                "PARS III Mk19 RWS":"pars",
                "PARS III M2 RWS":"pars",
                "PARS III 25mm":"pars",
                "M60T":"m60t"
            }
        }
    },
    methods:{

        /**
         * Notify parent to close the menu
         */
        closeMobileRightMenu(){
            this.$emit('closeMobileRightMenu')
        },

        /**
         * Notify parent to change the selected map 
         */
        changeSelectedMap(){
            this.$emit('changeMap', this.selectedMap)
            this.showBlufor = false
            this.showRedfor = false
        },

        /**
         * Notify parent to change the selected layer
         */
        changeSelectedLayer(){
            this.$emit('changeLayer', this.selectedLayer)
            this.showBlufor = false
            this.showRedfor = false
        },

        /**
         * Toggles the blufor component
         */
        toggleBlufor(){
            this.showBlufor = !this.showBlufor
        },

        /**
         * Toggles the redfor component
         */
        toggleRedfor(){
            this.showRedfor = !this.showRedfor
        },
        
        /**
         * Checks if layer is either RAAS or Invasion
         * @returns {boolean} True if so, False otherwise
         */
        isLanes(){
            if (this.layerInfos.layerName.includes('RAAS') || this.layerInfos.layerName.includes('Inv')) {
                return true
            }

            return false
        },

        /**
         * Returns the URL to Squad Lanes with mapNameLanes prop and current layer informations
         * @returns {String} URL to Squad Lanes's website
         */
         getSquadLanesURL(){
            var layerType = this.layerInfos.layerName.split(' ')[0]
            var layerVer = this.layerInfos.layerName.split(' ')[1]

            return 'https://squadlanes.com/#map=' + this.mapNameLanes + '&layer=' + layerType + '+' + layerVer
        },
        
        /**
         * Returns the URL to given vehicle icon type
         * @param {String} vhl selected vehicle
         * @returns {String} URL to the icon
         */
         getVehicleIcon(vhl){
            return '/utils/vehicles/' + vhl + '.png'
        }
    },
    props:{

        /**
         * Contains maps names
         * @type {Object}
         */
        maps:{
            type: Object,
            required: true
        },

        /**
         * Contains layers names of the selected map
         * @type {Array}
         */
        layers:{
            type: Array,
            required: true
        },
        
        /**
         * SquadLanes's URL name for the selected map, used to give the link on RAAS and Invasion layers
         * @type {String}
         */
         mapNameLanes:{
            type: String,
            required: true
        },

        /**
         * Selected layer informations
         * @type {Object}
         */
        layerInfos:{
            type: Object,
            required: true
        }
    },
    watch: {
        
        /**
         * On maps prop update the select tags are reset and 1st map is selected by default
         */
        maps:{
            immediate: true,

            handler(){
                this.selectedMap = Object.keys(this.maps)[0]
            }
        },
        
        /**
         * On layers prop update the select tags are reset and 1st map is selected by default
         */
        layers: {
            immediate: true,

            handler(){
                this.selectedLayer = this.layers[0]
            }
        }
    },
    computed:{

        /**
         * Returns the flags URL's 
         * @returns {Array<String>} containing the flages URL's for both factions
         */
        factionIcon(){
            return [
                '/utils/flags/' + this.factions[this.layerInfos.blufor.factionName] + '.png',
                '/utils/flags/' + this.factions[this.layerInfos.redfor.factionName] + '.png'
            ]
        }
    }
}
</script>

<style scoped>

#mobile-right-menu{
    background-color: rgba(20, 20, 20, 0.6);
    backdrop-filter: blur(20px);
    position: absolute;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: scroll;
    top: 0;
    left: 0;
    z-index: 10000;
}

#mobile-right-menu-close-button{
    position: absolute;
    top: 0;
    left: 0;
    width: 15vw;
    height: 15vw;
    padding: 1vh;
    background-color: transparent;
    backdrop-filter: none;
}

#mobile-right-content{
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

#mobile-right-layer{
    overflow: scroll;
}

#mobile-right-content>div{
    margin: 5vw;
    width: 90%;
}

#mobile-right-select{
    display: flex;
    flex-direction: column;
}

#mobile-right-select>select{
    flex: 1 1;
    font-size: 3vh;
}

.mobile-right-expandable{
    display: flex;
    flex-direction: column;
    padding: 1vh 0;
}

.mobile-right-expandable>*{
    transition: transform 0.3s ease-in-out;
    transform-origin: top;
}

#mobile-right-blufor:first-child, #mobile-right-redfor:first-child{
    cursor: pointer;
}

.dropdown-enter-from, .dropdown-leave-to{
    transform: scaleY(0);
}

.dropdown-btn{
    transition: all 0.3s;
}

.dropdown-btn.dropdown-active{
    transform: rotate(180deg);
}

.flexbox-row{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.faction-header{
    width: 35%;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.faction-flag{
    max-height: 3vh;
    width: auto;
    margin-right: 1vh;
}

.squadlanes-link, .squadlanes-link:active{
    color: white;
}

</style>