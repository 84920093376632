import * as mortars from "../assets/mortars.json";

/**
 * Array with specific muzzle velocities depending on distance
 * @type {Array<Array>}
 */
const ub32 = [
    [100, 335.1200224653227],
    [200, 305.9582960205495],
    [300, 283.34779711713827],
    [400, 269.43092929304714],
    [500, 256.00067568689457],
    [600, 246.0597855795005],
    [700, 230.23902655011395],
    [800, 227.72135414063865],
    [900, 227.10330164679877],
    [1000, 219.51863104815288],
    [1100, 217.92195975729098],
    [1200, 213.69727817913733],
    [1300, 217.46916795849413],
    [1400, 216.06414872060554],
    [1500, 213.86509885836008],
    [1600, 210.59377321283807],
    [1700, 208.55735633285997],
    [1800, 207.5305459239776],
    [1900, 207.7886602381275],
    [2000, 208.8396419902778],
    [2050, 206.39437348683896],
    [2100, 205.87235893665985],
    [2143, 205.08642659737743],
]

/**
 * Returns the mortar types from the JSON Object
 * 
 * @returns {Array<String>} An array containing the types (String)
 */
export function getMortarTypes(){

    // Getting keys from the mortars Object
    var arr = Object.keys(mortars)

    // Removing the last index which is "default"
    arr.pop()

    return arr
}

export class Mortar{

    name;
    muzzleVelocity;
    gravityMultiplier;
    elevationRange;
    angleUnit;
    directFire;
    icon;
    dispersion;
    maxRange;
    minRange;
    lat;
    lng;
    mapScale;

    constructor(mortarName, coordinates = {lat: null, lng: null}, mapSize = 1 ){

        this.name = mortars[mortarName].name
        this.muzzleVelocity = mortars[mortarName].muzzleVelocity
        this.gravityMultiplier = mortars[mortarName].gravityMultiplier
        this.elevationRange = mortars[mortarName].elevationRange
        this.angleUnit = mortars[mortarName].angleUnit
        this.directFire = mortars[mortarName].directFire
        this.icon = mortars[mortarName].icon
        this.dispersion = mortars[mortarName].dispersion
        this.maxRange = mortars[mortarName].maxRange
        this.minRange = mortars[mortarName].minRange
        this.lat = coordinates.lat
        this.lng = coordinates.lng
        this.mapScale = 4096 / mapSize
    }

    /**
     * Returns the mortar's coordinates as an object
     * 
     * @return {Object} Object with lat and lng
     */
    getCoordinates(){
        return {lat:this.lat, lng: this.lng}
    }

    /**
     * Returns bool to tell if the mortar has been placed
     * 
     * @return {bool} If placed or not
     */
    isPlaced(){
        if(this.lat == null || this.lng == null){
            return false
        }

        return true
    }

    /**
     * Used to set the coordinates of the mortar
     * 
     * @param {Object} latlng The lat and lng of the mortar placed on map
     * @param {number} mapScale The scale specific for the map the mortar is placed on
     */
    place(latlng, mapScale){
        this.lat = latlng.lat
        this.lng = latlng.lng
        this.mapScale = mapScale
    }

    /**
     * Converter from radians to degrees
     * 
     * @param {number} rad The radians value
     * @returns {number} Converted value
     */
    radToDeg(rad){
        return rad*180/Math.PI 
    }

    /**
     * Converter from radians to NATO mils
     * 
     * @param {number} rad The radians value
     * @returns {number} Converted value
     */
    radToMils(rad){
        return rad*1018.591636
    }

    /**
     * Returns the distance (in meters) between given target's coordinates and mortar's coordinates
     * 
     * @param {Object} latlng The target's coordinates
     * @return {number} The distance from mortar's coordinates
     */
    getDistance(latlng){
        var deltaLat = Math.abs(latlng.lat - this.lat)
        var deltaLng = Math.abs(latlng.lng - this.lng)

        var distance = Math.sqrt(deltaLat*deltaLat + deltaLng*deltaLng)

        distance /= this.mapScale

        return distance
    }

    /**
     * Returns the azimuth (in degrees, 1 decimal) between mortar's coordinates and given target's coordinates
     * 
     * @param {Object} latlng The target's coordinates
     * @return {number} The azimuth from mortar's coordinates
     */
    getAzimuth(latlng){

        // Calculating vectors from mortar to target
        var deltaLat = latlng.lat - this.lat
        var deltaLng = latlng.lng - this.lng

        // Calculating azimuth in degrees
        var azimuth = Math.atan2(deltaLng, deltaLat) * 180 / Math.PI

        // Calculated azimuth is between [-180;180] so < 0 means from 180 to 360°
        if(azimuth < 0){
            azimuth += 360
        }

        return azimuth.toFixed(1)
    }

    /**
     * Returns the muzzle velocity of the UB-32 which depends on distance
     * 
     * @param {number} distance : The distance in meters to the target
     * @return {number} The muzzle velocity approximation according to distance
     */
    findMuzzleVelUB32(distance){

        // Setting variables, inf is the index of the array under the desired distance
        var inf = 0
        var velocity = 0

        // Searching for inferior index
        for (let i = 0; i < ub32.length; i++) {
            if(ub32[i][0] >= distance){
                inf = i - 1
                break
            }
        }

        // Avoiding error if distance is under minimum value (< 100 m) the first value is returned (no difference at close range)
        if (inf <= 0) {
            return ub32[0][1]
        }

        // Approximating the return velocity between inferior and superior velocity
        velocity = ub32[inf][1] - ((Math.abs(ub32[inf][0] - distance) / 100) * (ub32[inf][1] - ub32[inf + 1][1]))

        return velocity
    }

    /**
     * Returns the maximum range in px according to map scale
     * 
     * @returns {number} The range in px
     */
    getMaxRange(){
        return this.maxRange * this.mapScale
    }

    /**
     * Returns the minimum range in px according to map scale
     * 
     * @returns {number} The range in px
     */
    getMinRange(){
        return this.minRange * this.mapScale
    }

    /**
     * Returns the dispersion in meters according to the distance and mortar's precision
     * 
     * @param {Object} latlng Target's coordinates
     * @returns {number} Dispersion in meters
     */
    getDispersion(latlng){
        var ret = (0.00029 * this.dispersion * 2) * this.getDistance(latlng)

        return Math.round(ret)
    }

    /**
     * Returns the elevation needed for the mortar to hit the target
     * 
     * @param {Object} latlng The target's coordinates
     * @param {number} deltaZ Height difference between target and mortar ( < 0 : under mortar, > 0 : over)
     * @returns {String} The elevation needed with angleUnit attached to it
     */
    calculateElevation(latlng, deltaZ){
        var distance = this.getDistance(latlng)
        
        if (distance >= this.maxRange) {
            return "Too far"
        }
        if (distance < this.minRange) {
            return "Too close"
        }

        // Setting variables
        var g = 9.807 * this.gravityMultiplier
        var muzzleVel = this.muzzleVelocity
        var elevation = 0

        //console.log(distance + " " + muzzleVelocity + " " + g) // Debugging purpose

        // Specific case with the UB-32's muzzle velocity depending on target's distance
        if(muzzleVel == 0){
            muzzleVel = this.findMuzzleVelUB32(distance)
        }

        var root = Math.sqrt(muzzleVel ** 4 - g * (g * distance ** 2 + 2 * deltaZ * muzzleVel ** 2));

        // Calculating the elevation needed to hit the target if the mortar is direct fire (UB32, Grad)
        if(this.directFire){

            // Ballistic trajectory calculation
            elevation = Math.atan((muzzleVel ** 2 - root) / (g * distance));

            // Conversion to degrees (no mils used by direct fire mortars ATM)
            elevation = this.radToDeg(elevation)

            //console.log(ret) // Debugging purpose

            // Checking if the result is in mortar elevation bounds
            if (this.elevationRange[0] > elevation) {
                return "Too close"
            }
            else if (this.elevationRange[1] < elevation) {
                return "Too far"
            }
        }
        // Calculating the elevation needed to hit the target if the mortar is undirect fire (Mortar, Hell Cannon)
        else{

            // Ballistic trajectory calculation
            elevation = Math.atan((muzzleVel ** 2 + root) / (g * distance));

            // Converting result to mils or degrees
            if(this.angleUnit == "mils"){
                elevation = this.radToMils(elevation)
            }
            else{
                elevation = this.radToDeg(elevation)
            }
            
            // Checking if the result is in mortar elevation bounds
            if (this.elevationRange[0] > elevation) {
                return "Too far"
            }
            else if (this.elevationRange[1] < elevation) {
                return "Too close"
            }
        }

        // Return pre-made sentence with angleUnit
        return elevation.toFixed(1) + " " + this.angleUnit
    }

}