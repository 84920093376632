<template>
    <div id="mobile-left-menu">
        <button id="mobile-left-menu-close-button" @click="closeMobileLeftMenu">X</button>
        <div id="mobile-left-content">
            <div>Mortar Calc</div>
            <div style="display: flex; width: 90%;">
                <select style="font-size: 3vh; flex: 1 1;" name="mortarType" v-model="selectedMortar" @change="changeMortarType">
                    <option v-for="mortarType in this.mortarTypes" :key="mortarType" :value="mortarType">{{ mortarType }}</option>
                </select>
            </div>
            <div style="text-align: center;">
                Mortar coords :&nbsp;
                <div style="border: solid 1px white; padding: 0 0.25rem;">{{ this.mortarCoords.letter }}{{ this.mortarCoords.digit }} - {{ this.mortarCoords.firstDec }} - {{ this.mortarCoords.secDec }}</div>
                <button v-if="this.mortarCoords.digit != 'x'" @click="this.resetMortar">Reset mortar</button>
            </div>
            <div id="mortar-table" class="div-table">
                <div class="thead">
                    <div class="delete-marker" style="flex-basis: 20%;"><img src="/utils/left/bin.png" alt="X" style="height: 1rem; width: auto;" @click="resetTargets"></div>
                    <div class="data-cell" style="flex-basis: 80%;"><img src="utils/left/map-pin.png" alt="Coords." style="height: 1rem; width: auto;"></div>
                    <div class="data-cell" style="flex-basis: 80%;"><img src="utils/left/compass.png" alt="Dir." style="height: 1rem; width: auto;"></div>
                    <div class="data-cell" style="flex-basis: 100%;">Elev.</div>
                </div>
                <div class="tbody">
                    <div class="tr" v-if="this.mortarCoords.firstDec == 'x'">
                        <div class="data-cell">Click on the map to add the mortar</div>
                    </div>
                    <div class="tr" v-else-if="this.targetArray.length < 1">
                        <div class="data-cell">Click on the map to add a target</div>
                    </div>
                    <div class="tr" v-for="target in this.targetArray" :key="target">
                        <div class="delete-marker" style="flex-basis: 20%;"><a @click="deleteMarker(target.id)">X</a></div>
                        <div class="data-cell" style="flex-basis: 80%;">{{ target.alphaCoords.letter }}{{ target.alphaCoords.digit }} - {{ target.alphaCoords.firstDec }} - {{ target.alphaCoords.secDec }}</div>
                        <div class="data-cell" style="flex-basis: 80%;">{{ target.azimuth }} °</div>
                        <div class="data-cell" style="flex-basis: 100%;">{{ target.elevation }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            /**
             * String of the current selected mortar, initialized below
             * @type {String}
             */
             selectedMortar: "",

            /**
             * Object containing the coordinates of the mortar on map
             * @type {Object}
             */
            mortarCoords: {
                letter: "x",
                digit: "x",
                firstDec: "x",
                secDec: "x"
            },

            /**
             * Array containing every target's data to be displayed on the table
             * @type {Array<Object>}
             */
            targetArray: []
        }
    },
    methods:{

        /**
         * Notify parent to close the menu
         */
        closeMobileLeftMenu(){
            console.log("close left !");
            this.$emit('closeMobileLeftMenu')
        },

        /**
         * Notify parent that the mortar selected has changed
         */
         changeMortarType(){
            this.$emit('setMortarType', this.selectedMortar)
        },
        /**
         * Notify parent to reset the mortar on map
         */
        resetMortar(){
            this.$emit('resetMortar')
        },
        /**
         * Notify parent to reset the targets on map
         */
        resetTargets(){
            this.$emit('resetTargets')
        },
        /**
         * Notify parent to remove specific target on map given its _leaflet_id
         * 
         * @param {number} id _leaflet_id of the marker to delete from the map
         */
        deleteMarker(id){
            //console.log(id);
            this.$emit('deleteMarker', id)
        }
    },
    props:{

        // Array containing the mortar types to display in the select element
        mortarTypes:{
            type:Array,
            required:true
        }
    },
    mounted(){

        // Inits the mortar select element with the first mortar of the list
        this.selectedMortar = this.mortarTypes[0]
        this.changeMortarType()
    }
}
</script>

<style scoped>

#mobile-left-menu{
    background-color: rgba(20, 20, 20, 0.6);
    backdrop-filter: blur(20px);
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    transform-origin: left;
}

#mobile-left-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#mobile-left-content>div{
    margin: 5vw;
}

#mobile-left-menu-close-button{
    position: absolute;
    top: 0;
    right: 0;
    width: 15vw;
    height: 15vw;
    padding: 1vh;
    background-color: transparent;
    backdrop-filter: none;
}

</style>