<template>

        <div id="left-menu"  class="vertical-center">

            <div id="mortar-settings" class="left-menu-row">
                <div style="font-size: 2vh;">Mortar selected :</div>
                <select style="font-size: 1.5vh;" name="mortarType" v-model="selectedMortar" @change="changeMortarType">
                    <option v-for="mortarType in this.mortarTypes" :key="mortarType" :value="mortarType">{{ mortarType }}</option>
                </select>
                <button style="padding: 0.5vh;" v-if="this.mortarCoords.firstDec != 'x'" @click="resetMortar">Reset mortar</button>
                <button style="padding: 0.5vh;" v-else :class="{active: this.placeMortarButtonActive}" @click="placeMortar">Place mortar</button>
                <button style="padding: 0.5vh;" v-if="this.mortarCoords.firstDec != 'x'" :class="{active: this.freezeMortarButtonActive}" @click="freezeMortar">Freeze mortar</button>
            </div>

            <div id="left-menu-coords" class="left-menu-row">
                <div style="display: flex; flex-direction: row; align-items: center;">
                    Mortar coords :&nbsp;<div style="border: solid 1px white; padding: 0 0.25rem;">{{ this.mortarCoords.letter }}{{ this.mortarCoords.digit }} - {{ this.mortarCoords.firstDec }} - {{ this.mortarCoords.secDec }}</div>
                </div>
            </div>

            <div id="mortar-table" class="div-table">
                <div class="thead">
                    <div class="delete-marker" style="flex-basis: 20%;"><img src="/utils/left/bin.png" alt="X" style="height: 1rem; width: auto;" @click="resetTargets"></div>
                    <div class="data-cell" style="flex-basis: 80%;"><img src="utils/left/map-pin.png" alt="Coords." style="height: 1rem; width: auto;"></div>
                    <div class="data-cell" style="flex-basis: 80%;">Dist.</div>
                    <div class="data-cell" style="flex-basis: 80%;"><img src="utils/left/compass.png" alt="Dir." style="height: 1rem; width: auto;"></div>
                    <div class="data-cell" style="flex-basis: 100%;">Elev.</div>
                    <div class="data-cell" style="flex-basis: 80%;">Disp.</div>
                </div>
                <div class="tbody">
                    <div class="tr" v-if="this.mortarCoords.firstDec == 'x'">
                        <div class="data-cell">Click on the map to add the mortar</div>
                    </div>
                    <div class="tr" v-else-if="this.targetArray.length < 1">
                        <div class="data-cell">Click on the map to add a target</div>
                    </div>
                    <div class="tr" v-for="target in this.targetArray" :key="target">
                        <div class="delete-marker" style="flex-basis: 20%;"><a @click="deleteMarker(target.id)">X</a></div>
                        <div class="data-cell" style="flex-basis: 80%;">{{ target.alphaCoords.letter }}{{ target.alphaCoords.digit }} - {{ target.alphaCoords.firstDec }} - {{ target.alphaCoords.secDec }}</div>
                        <div class="data-cell" style="flex-basis: 80%;">{{ target.distance }} m</div>
                        <div class="data-cell" style="flex-basis: 80%;">{{ target.azimuth }} °</div>
                        <div class="data-cell" style="flex-basis: 100%;">{{ target.elevation }}</div>
                        <div class="data-cell" style="flex-basis: 80%;">{{ target.dispersion }} m</div>
                    </div>
                </div>
            </div>
        </div>   
    
</template>

<script>
export default{
    data(){
        return{
            /**
             * String of the current selected mortar, initialized below
             * @type {String}
             */
            selectedMortar: "",

            /**
             * Object containing the coordinates of the mortar on map
             * @type {Object}
             */
            mortarCoords: {
                letter: "x",
                digit: "x",
                firstDec: "x",
                secDec: "x"
            },

            /**
             * Array containing every target's data to be displayed on the table
             * @type {Array<Object>}
             */
            targetArray: [],
            
            /**
             * Visual purpose
             * @type {boolean}
             */
            placeMortarButtonActive: false,

            /**
             * Visual purpose
             * @type {boolean}
             */
            freezeMortarButtonActive: false,
        }
    },
    props:{

        // Array containing the mortar types to display in the select element
        mortarTypes:{
            type:Array,
            required:true
        }
    },
    methods:{

        /**
         * Notify parent that the mortar selected has changed
         */
        changeMortarType(){
            this.$emit('setMortarType', this.selectedMortar)
        },
        /**
         * Notify to parent that mortar placement button has been clicked so the user can click on map to place the mortar
         */
         placeMortar(){
            this.placeMortarButtonActive = !this.placeMortarButtonActive

            this.$emit('placeMortar', this.placeMortarButtonActive)
            
            //console.log(this.placeMortarButtonActive);
        },

        /**
         * Notify to parent that mortar freeze button has been clicked so the user can click on the map without placing targets
         */
        freezeMortar(){
            this.freezeMortarButtonActive = !this.freezeMortarButtonActive

            this.$emit('freezeMortar', this.freezeMortarButtonActive)
        },
        /**
         * Notify parent to reset the mortar on map
         */
        resetMortar(){
            this.placeMortarButtonActive = false
            this.$emit('resetMortar')
        },
        /**
         * Notify parent to reset the targets on map
         */
        resetTargets(){
            this.$emit('resetTargets')
        },
        /**
         * Notify parent to remove specific target on map given its _leaflet_id
         * 
         * @param {number} id _leaflet_id of the marker to delete from the map
         */
        deleteMarker(id){
            //console.log(id);
            this.$emit('deleteMarker', id)
        }
    },
    mounted(){

        // Inits the mortar select element with the first mortar of the list
        this.selectedMortar = this.mortarTypes[0]
        this.changeMortarType()
    }
}
</script>

<style scoped>
table{
    text-align: center;
}

th, td{
    padding: 0.5rem;
}

tr img{
    max-height: 1rem;
    max-width: 1rem;
    height: auto;
    width: auto;
}

table a:hover {
    cursor: pointer;
}

#left-menu{
  position: absolute;
  left: 0;
  max-width: 30vw;
  max-height: 50vh;
  width: 30vw;
  overflow-y: hidden;
  color: white;
  padding: 0.5rem;
  background-color: rgba(20, 20, 20, 0.6);
  backdrop-filter: blur(20px);
  border-bottom: solid 3px #908F8E;
  border-top: solid 3px #908F8E;
  border-right: solid 3px #908F8E;
  z-index: 50;
}

#left-menu *{
    transition: 0.5s ease-in-out;
}

.left-menu-row{
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  align-items: center;
}

#left-menu-coords{
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-evenly;
}

</style>