<template>
    <div id="map" style="height: 100vh; width: 100vw;"></div>
</template>

<script>
import L from 'leaflet'
import { Mortar } from '@/assets/Mortar'
import { HeightCalc } from '@/assets/HeightCalc'

export default{
    data(){
        return {

            /**
             * Map bounds
             * @type {L.latLngBounds}
             */
            bounds: L.latLngBounds([4096,4096], [0,0]),

            /**
             * Minimum zoom value
             * @type {number}
             */
            minZoom: -3,

            /**
             * Alphabet ordered letters
             * @type {Array<String>}
             */
            letters: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],

            /**
             * Numpad digits 
             * @type {Array<Array>}
             */
            digits: [
                [7,8,9],
                [4,5,6],
                [1,2,3]
            ],

            /**
             * Mouse coordinates
             * @type {Object}
             */
            mouseCoords:{letter: "A", digit: 1, firstDec: 1, secDec: 1},

            /**
             * CRS type used by the map
             * @type {L.CRS.Simple}
             */
            crs: L.CRS.Simple,

            /**
             * Center coordinates of the map
             * @type {number}
             */
            center: [2048, 2048],

            /**
             * Available game maps array
             * @type {Array}
             */
            maps: [],

            /**
             * Leaflet Map
             * @type {L.Map}
             */
            map: L.Map,

            /**
             * Mortar coordinates if placed
             * @type {Object}
             */
            mortarCoords: {},

            /**
             * True to show the grid on the map, false otherwise
             * @type {boolean}
             */
            showGrid: true,

            /**
             * True to show the objectives on the map, false otherwise
             * @type {boolean}
             */
            showObjectives: true,

            /**
             * True to show the mortar on the map, false otherwise
             * @type {boolean}
             */
            showMortar: true,

            /**
             * LayerGroup containing all layers related to the mortar (range circles, targets)
             * @type {L.LayerGroup}
             */
            mortarLayerGroup: L.LayerGroup,

            /**
             * LayerGroup containing all layers related to the objectives (flags, main bases)
             * @type {L.LayerGroup}
             */
            objectivesLayerGroup: L.LayerGroup,

            /**
             * LayerGroup containing all layers related to the grid
             * @type {L.LayerGroup}
             */
            gridLayerGroup: L.LayerGroup,

            /**
             * Contains the translation from Squad's factions names to simpler names used on the app
             * @type {Object}
             */
            factions:{
                "United States Army": "USA",
                "Middle Eastern Alliance": "MEA",
                "Insurgent Forces": "INS",
                "Australian Defence Force": "ADF",
                "British Armed Forces": "BAF",
                "United States Marine Corps": "USMC",
                "Canadian Armed Forces": "CAF",
                "People's Liberation Army": "PLA",
                "Irregular Militia Forces": "MIL",
                "Russian Ground Forces": "RGF",
                "Russian Airborne Forces": "VDV",
                "PLA Navy Marine Corps": "PLANMC",
                "Turkish Land Forces": "TLF"
            },

            /**
             * The mortar 
             * @type {Mortar}
             */
            mortar: Mortar,

            /**
             * Class used to calculate height difference between mortar and target
             * @type {HeightCalc}
             */
            heightCalc: HeightCalc,

            /**
             * Selected mortar from the left menu
             * @type {String}
             */
            selectedMortar: "",

            /**
             * Contains all targets data
             * @type {Array<Object>}
             */
            targetArray: [],
            
            /**
             * Variable telling if mortar can be placed (not interfering with RAAS or Inv lanes)
             * @type {boolean}
             */
             mortarActive: false
        }
    },
    props:{

        /**
         * Selected map linked to parents attribute
         * @type {String}
         */
        selectedMapVue:{
            type: String,
            required: true,
            default: 'AlBasrah'
        },

        /**
         * Selected map data linked to parents attribute
         * @type {Object}
         */
        selectedMapInfos:{
            type: Object,
            default: () => ({
                name: "AlBasrah",
                size: 3200,
                title: "Al Basrah",
                lanesTitle: "Al+Basrah"
            })
        },

        /**
         * Selected layer data linked to parents attribute
         * @type {Object}
         */
        selectedLayerInfos:{
            type: Object
        }
    },
    computed: {

        /**
         * Returns the URL to JPG map for given map and layer (some are specific like flooded, etc...)
         * 
         * @returns {String} URL to the JPG map
         */
        url() {
            return '/maps/' + this.selectedMapVue + '/normal.jpg'
        }
    },
    methods:{

        /**
         * Checks if the click is in the map bounds
         * 
         * @param {L.LatLng} click Clicks coordinates
         * @returns {boolean} True if so, false otherwise
         */
        clickOnMap(click){
            if(click.lat >= 0 && click.lat <= 4096 && click.lng >= 0 && click.lng <= 4096){
                return true
            }

            return false
        },

        /**
         * Converts the radians to degrees
         * 
         * @param {number} rads Angle in radians
         * @returns {number} Angle in degrees
         */
        radToDeg(rads){
            return rads*180/Math.PI
        },

        /**
         * Handles map click
         * 
         * @param {event} e Event data
         * @returns if mortar isn't displayer or click out of map bounds
         */
        mapClick(e){ 

            if (this.clickOnMap(e.latlng) == false || this.showMortar == false || this.mortarActive == false) {
                return
            }

            var icone = L.Icon

            // Calculating map resolution
            var res = 4096 / this.selectedMapInfos.size

            var marker = L.Marker

            // Handling mortar placement
            if (this.mortar.isPlaced() == false) {

                // Placing mortar Object
                this.mortar.place(e.latlng, res)

                // Creating visual aspect of the mortar with range circles, marker
                var mortarLayer = new L.LayerGroup([], {
                    pane: 'mortar'
                })

                var minRangeCircle = new L.Circle(e.latlng, {
                    radius: this.mortar.getMinRange(),
                    color: '#ff0000',
                    weight: 2
                })

                var rangeCircle = new L.Circle(e.latlng, {
                    radius: this.mortar.getMaxRange(),
                    color:'#000000',
                    weight: 2,
                    fillOpacity: 0
                })

                icone = new L.Icon({
                    iconUrl: '/utils/mortar/' + this.mortar.icon + '.png',
                    iconSize: [48, 48],
                    iconAnchor: [24,24],
                    className: 'mortar-icon'
                })
                marker = new L.Marker(e.latlng,{
                    icon: icone,
                    draggable: false,
                    pane: 'mortar'
                })

                // Remove mortar on click
                marker.on('click', this.resetMortar)

                // Adding mortar visual elements to its LayerGroup
                marker.addTo(mortarLayer)
                minRangeCircle.addTo(mortarLayer)
                rangeCircle.addTo(mortarLayer)

                // Notify parent that mortar is placed (used on left menu)
                this.$emit('mortar', this.pxToAlphaCoords(e.latlng))

                // Adding mortar LayerGroup to the global LayerGroup (containing also targets)
                mortarLayer.addTo(this.mortarLayerGroup)

            } 
            // If mortar is placed it means we add a target
            else {

                /**
                 * Height difference between mortar and target
                 * @type {number}
                 */
                var deltaZ = this.heightCalc.getHeightDifference(this.mortar.getCoordinates(), e.latlng)

                /**
                 * Azimuth from mortar to target
                 * @type {number}
                 */
                var az = this.mortar.getAzimuth(e.latlng)

                /**
                 * Mortar elevation needed to hit the target
                 * @type {String} (unit already given)
                 */
                var el = this.mortar.calculateElevation(e.latlng, deltaZ)

                /**
                 * Dispersion due to distance
                 * @type {number}
                 */
                var dsp = this.mortar.getDispersion(e.latlng)

                /**
                 * Distance from mortar to target
                 * @type {number}
                 */
                var dst = this.mortar.getDistance(e.latlng)

                // Creating visual aspect of the target
                icone = new L.DivIcon({
                    html: '<img style="height:48px; width: auto;" src="utils/mortar/target.png"><div>' + az + '°<br>' + el + '</div>',
                    iconSize: [128,96],
                    iconAnchor: [64,48],
                    className: 'mortar-target-icon'
                })
                marker = new L.Marker(e.latlng,{
                    icon: icone,
                    draggable: true,
                    pane: 'targets'
                })

                // Handling click on target (remove it)
                marker.on('click', this.removeMarkerEvent)

                // Handling target moving (refresh data)
                marker.on('move', this.moveMarkerEvent)

                // Adding marker to the global mortar LayerGroup
                marker.addTo(this.mortarLayerGroup)

                // Adding data to the targetArray (will be sent below)
                this.targetArray.push({
                    id: marker._leaflet_id,
                    latlng: e.latlng,
                    alphaCoords: this.pxToAlphaCoords(e.latlng),
                    azimuth: az,
                    elevation: el,
                    distance: Math.round(dst),
                    dispersion: dsp,
                    deltaZ: deltaZ
                })

                // Send target array to parent (displayed in the left menu)
                this.$emit('targetArray', this.targetArray)
            }
        },

        /**
         * Handles marker move
         * 
         * @param {event} e The event data
         */
        moveMarkerEvent(e){
            
            // Getting all data needed to refresh target
            var deltaZ = this.heightCalc.getHeightDifference(this.mortar.getCoordinates(), e.latlng)
            var az = this.mortar.getAzimuth(e.latlng)
            var el = this.mortar.calculateElevation(e.latlng, deltaZ)
            var dsp = this.mortar.getDispersion(e.latlng)
            var dst = this.mortar.getDistance(e.latlng)

            // Refreshing target on map
            e.target._icon.lastChild.innerHTML = az + '°<br>' + el

            // Updating targets array
            for (let i = 0; i < this.targetArray.length; i++) {
                if (this.targetArray[i].id == e.target._leaflet_id) {
                    this.targetArray[i].latlng = e.latlng
                    this.targetArray[i].alphaCoords = this.pxToAlphaCoords(e.latlng)
                    this.targetArray[i].azimuth = az
                    this.targetArray[i].elevation = el
                    this.targetArray[i].dispersion = dsp
                    this.targetArray[i].distance = Math.round(dst)
                    this.targetArray[i].deltaZ = deltaZ
                }
            }

            // Sending targets array to parent
            this.$emit('targetArray', this.targetArray)
        },

        /**
         * Redraws mortar on the map
         */
        redrawMortar(){

            // Getting mortar data
            var mortarCoords = this.mortar.getCoordinates()
            var maxRange = this.mortar.getMaxRange()
            var minRange = this.mortar.getMinRange()

            // Deleting mortar from map (not targets)
            this.mortarLayerGroup.getLayers()[0].clearLayers()

            // Recreating visual aspect of the mortar with range circles, marker
            var icone = new L.Icon({
                iconUrl: '/utils/mortar/' + this.mortar.icon + '.png',
                iconSize: [48, 48],
                iconAnchor: [24,24],
                className: 'mortar-icon'
            })

            var marker = new L.Marker(mortarCoords,{
                icon: icone,
                draggable: false
            })

            var rangeCircle = new L.Circle(mortarCoords, {
                radius: maxRange,
                color:'#000000',
                weight: 2,
                fillOpacity:0
            })

            var minRangeCircle = new L.Circle(mortarCoords, {
                radius: minRange,
                color: '#ff0000',
                weight: 2
            })


            // Adding mortar visual elements to its LayerGroup
            marker.addTo(this.mortarLayerGroup.getLayers()[0])
            minRangeCircle.addTo(this.mortarLayerGroup.getLayers()[0])
            rangeCircle.addTo(this.mortarLayerGroup.getLayers()[0])

            // Redraws targets to update data (elevation due to mortar type change)
            this.redrawTargets()

        },

        /**
         * Redraws targets on map
         */
        redrawTargets(){
            for (let i = 1; i < this.mortarLayerGroup.getLayers().length; i++) {

                // Updates the elevation need for the new mortar type
                var elevation = this.mortar.calculateElevation(this.targetArray[i-1].latlng, this.targetArray[i-1].deltaZ)

                // Updates the data displayed on each target 
                this.mortarLayerGroup.getLayers()[i]._icon.lastChild.innerHTML = this.targetArray[i-1].azimuth + '°<br>' + elevation
            
                // Updates targets array with correct elevation
                this.targetArray[i - 1].elevation = elevation         
            }

            // Sending targets array to parent
            this.$emit('targetArray', this.targetArray)
        },

        /**
         * Converts latlng coordinates (in px) to Squad Game coordinates
         * 
         * @param {Object} latlng Coordinates to convert
         * @returns {Object} Converted coordinates
         */
        pxToAlphaCoords(latlng){
            var metterLat = Math.abs(((latlng.lat  - 4096)/(4096/this.selectedMapInfos.size)))
            var metterLng = (latlng.lng/(4096/this.selectedMapInfos.size))

            var letter = Math.floor(metterLng/300)
            var digit = Math.floor(metterLat/300) + 1

            var latFirstDec = Math.floor((metterLat % 300) / 100)
            var lngFirstDec = Math.floor((metterLng % 300) / 100)

            var latSecDec = Math.floor(((metterLat % 300) % 100) / (100 / 3))
            var lngSecDec = Math.floor(((metterLng % 300) % 100) / (100 / 3))

            return {
                letter: this.letters[letter], 
                digit: digit, 
                firstDec: this.digits[latFirstDec][lngFirstDec], 
                secDec: this.digits[latSecDec][lngSecDec]
            }
        },

        /**
         * Sends to parend live mouse coordinates over the map
         * @param {event} e The mouse event
         * @returns {bool} If mouse's not over map
         */
        setMouseCoordinates(e){

            if (this.clickOnMap(e.latlng) == false) {
                return
            }

            this.$emit('mouseCoords', this.pxToAlphaCoords(e.latlng))
        },

        /**
         * Checks if screen display is mobile (or portrait)
         * @returns {bool} True if mobile, false otherwise
         */
        isMobile(){
            return window.innerHeight > window.innerWidth ? true : false
        },

        /**
         * Initializes the map
         */
        setMap(){

            console.log("setMap called");

            if(this.map._container != undefined){
                this.map.remove()
            }

            if(this.isMobile()){
                this.minZoom = -4
            }
        
            this.map = new L.map('map', {
                crs: L.CRS.Simple,
                minZoom: this.minZoom,
                maxZoom: 3,
                zoom: this.minZoom,
                center: this.center,
                bounds: this.bounds,
                maxBounds: [[8096,8096],[-4000,-4000]],

            })

            this.map.createPane('grid-background')
            this.map.createPane('grid')
            this.map.createPane('objectives')
            this.map.createPane('mortar')
            this.map.createPane('targets')

            this.mortarLayerGroup = new L.LayerGroup([],{
                pane: 'mortar'
            })
            this.objectivesLayerGroup = new L.LayerGroup([],{
                pane: 'objectives'
            })

            this.gridLayerGroup = new L.LayerGroup([],{
                pane: 'grid'
            })

            this.mortarLayerGroup.addTo(this.map)

            this.map.on('mousemove', this.setMouseCoordinates)

            this.map.on('click', this.mapClick)

            var image = this.url

            var imageMap = new L.ImageOverlay(image, this.bounds, {
                zIndex: 0
            })
            
            imageMap.addTo(this.map)

            this.heightCalc = new HeightCalc(this.selectedMapVue, this.selectedMapInfos.zStep)

            var resolution = 4096 / ((Math.abs(this.selectedMapInfos.cornersCoordinates.nw.x) + Math.abs(this.selectedMapInfos.cornersCoordinates.se.x)) / 100)

            var metters300 = resolution * 300

            var metters100 = resolution * 100

            var metters33 = resolution * (100/3)

            var gridLetterBackground = new L.Rectangle([[4096,0],[4096 + 150,4096]],{
                stroke: false,
                fill: true,
                fillColor: '#000000',
                fillOpacity: 1,
                interactive: false,
                pane: 'grid-background'
            })

            var gridNumberBackground = new L.Rectangle([[0, -150],[4096,0]],{
                stroke: false,
                fill: true,
                fillColor: '#000000',
                fillOpacity: 1,
                interactive: false,
                pane: 'grid-background'
            })

            gridLetterBackground.addTo(this.gridLayerGroup)
            gridNumberBackground.addTo(this.gridLayerGroup)

            for (let i = 1; i <= Math.floor(4096 / metters33); i++) {
                var line33 = new L.Polyline([[4096 - (i*metters33), 0], [4096 - (i*metters33), 4096]], {
                    color: 'white',
                    weight: 0.25,
                    opacity: 0.75,
                    pane: 'grid'
                })

                var col33 = new L.Polyline([[0,i*metters33], [4096,i*metters33]], {
                    color: 'white',
                    weight: 0.25,
                    opacity: 0.75,
                    pane: 'grid'
                })

                col33.addTo(this.gridLayerGroup)
                line33.addTo(this.gridLayerGroup)
            }

            for (let i = 1; i <= Math.floor(4096 / metters100); i++) {
                var line100 = new L.Polyline([[4096 - (i*metters100), 0], [4096 - (i*metters100), 4096]], {
                    color: 'black',
                    weight: 0.5,
                    opacity: 0.75,
                    pane: 'grid'
                })

                var col100 = new L.Polyline([[0,i*metters100], [4096,i*metters100]], {
                    color: 'black',
                    weight: 0.5,
                    opacity: 0.75,
                    pane: 'grid'
                })

                col100.addTo(this.gridLayerGroup)
                line100.addTo(this.gridLayerGroup)
            }

            for (let i = 1; i <= Math.floor(4096 / metters300); i++) {
                var line300 = new L.Polyline([[4096 - (i*metters300), 0], [4096 - (i*metters300), 4096]], {
                    color: 'black',
                    weight: 0.75,
                    opacity: 0.75,
                    pane: 'grid'
                })

                var col300 = new L.Polyline([[0,i*metters300], [4096,i*metters300]], {
                    color: 'black',
                    weight: 0.75,
                    opacity: 0.75,
                    pane: 'grid'
                })

                var gridLetterIcon = new L.DivIcon({
                    html: this.letters[i-1],
                    iconSize: [15,15],
                    iconAnchor: [7.5,7.5],
                    className: 'map-grid-icons'
                })

                var gridLetter = new L.Marker([4096 + 75,((i - ( 1/2 )) * metters300)],{
                    icon: gridLetterIcon,
                    interactive: false,
                    pane: 'grid'
                })

                var gridNumberIcon = new L.DivIcon({
                    html: i,
                    iconSize: [15,15],
                    iconAnchor: [7.5,7.5],
                    className: 'map-grid-icons'                    
                })

                var gridNumber = new L.Marker([4096 - ((i - ( 1/2 )) * metters300), - 75],{
                    icon: gridNumberIcon,
                    interactive: false,
                    pane: 'grid'
                })

                col300.addTo(this.gridLayerGroup)
                line300.addTo(this.gridLayerGroup)
                gridLetter.addTo(this.gridLayerGroup)
                gridNumber.addTo(this.gridLayerGroup)
            }

            this.gridLayerGroup.addTo(this.map)

            this.map.getPane('grid-background').style.zIndex = 400
            this.map.getPane('grid').style.zIndex = 500
            this.map.getPane('objectives').style.zIndex = 600
            this.map.getPane('mortar').style.zIndex = 1000
            this.map.getPane('targets').style.zIndex = 1001
            
            this.map.setView([2048,2048], this.minZoom)

            if (this.mortar.isPlaced()) {
                this.resetMortar()
            }

            //this.setObjectives()
        },

        /**
         * Resets the mortar and its targets
         */
        resetMortar(){
            this.mortarLayerGroup.clearLayers()
            
            this.$emit('mortar', {
                letter: "x",
                digit: "x",
                firstDec: "x",
                secDec: "x"
            })

            this.mortar = new Mortar(this.selectedMortar)
            this.targetArray = []
            this.mortarActive = false
            this.$emit('targetArray', this.targetArray)
        },

        /**
         * Resets the mortar targets only
         */
        resetTargets(){
            for (let i = 0; i < this.targetArray.length; i++) {
                this.mortarLayerGroup.removeLayer(this.targetArray[i].id)
            }
            this.targetArray = []
            this.$emit('targetArray', this.targetArray)
        },

        /**
         * Toggles to show or hide the mortar on the map
         */
        toggleMortar(){
            if (this.showMortar) {
                this.mortarLayerGroup.remove()
            } else {
                this.mortarLayerGroup.addTo(this.map)
            }
            this.showMortar = !this.showMortar
        },

        /**
         * Handles click on marker to remove it
         * 
         * @param {event} e Event thrown
         */
        removeMarkerEvent(e){
            this.mortarLayerGroup.removeLayer(e.sourceTarget._leaflet_id)

            var index = 0

            // Searching for the index of the target removed
            for (let i = 0; i < this.targetArray.length; i++) {
                if (this.targetArray[i].id == e.target._leaflet_id) {
                    index = i
                }
            }

            // Removing also target from the targets array
            this.targetArray.splice(index, 1)

            this.$emit('targetArray', this.targetArray)
        },

        /**
         * Removes a specific target given its _leaflet_id
         * 
         * @param {number} id The target's id
         */
        removeMarkerById(id){
            this.mortarLayerGroup.removeLayer(id)

            var index = 0

            // Searching for the index of the target removed
            for (let i = 0; i < this.targetArray.length; i++) {
                if (this.targetArray[i].id == id) {
                    index = i
                }
            }

            // Removing also target from the targets array
            this.targetArray.splice(index, 1)

            this.$emit('targetArray', this.targetArray)
        },

        /**
         * Toggles to show or hide the grid on the map
         */
        toggleGrid(){
            if (this.showGrid) {
                this.gridLayerGroup.remove()
                this.showGrid = !this.showGrid
            }
            else{
                this.gridLayerGroup.addTo(this.map)
                this.showGrid = !this.showGrid
            }
        },

        /**
         * Toggles to show or hide the objectives on the map
         */
        toggleObjectives(){
            if (this.showObjectives) {
                this.objectivesLayerGroup.remove()
                this.showObjectives = !this.showObjectives
            } else {
                this.objectivesLayerGroup.addTo(this.map)
                this.showObjectives = !this.showObjectives
            }
        },

        /**
         * Converts centimeters coordinates from the game to px coordinates used by Leaflet Map
         * 
         * @param {number} metersLat Games coordinates latitude
         * @param {number} metersLng Games coordinates longitude
         * @returns {Object} Containing the coordinates used on the map
         */
        coordinatesConverter(metersLat, metersLng){

            var retLng = ((metersLng - this.selectedMapInfos.offset[0]) * 4096) / (Math.abs(this.selectedMapInfos.cornersCoordinates.nw.x) + Math.abs(this.selectedMapInfos.cornersCoordinates.se.x))
            
            var retLat = ((metersLat - this.selectedMapInfos.offset[1]) * 4096) / (Math.abs(this.selectedMapInfos.cornersCoordinates.nw.y) + Math.abs(this.selectedMapInfos.cornersCoordinates.se.y))

            return {
                lat: Math.abs(retLat - 4096),
                lng: retLng
            }

        },

        /**
         * Adds the main bases on the map
         */
        setMains(){
            var bluforMainIcon = new L.DivIcon({
                html: '<img style="height: auto; width: 48px" src="/utils/flags/'+ this.factions[this.selectedLayerInfos.blufor.factionName] + '.png" alt="flag"><img style="height: auto; width: 48px" src="utils/main_icon.png" alt="main">',
                iconSize: [48,72],
                iconAnchor: [24,72],
                className: 'objective-icon'
            })

            var bluforMain = new L.Marker(this.coordinatesConverter(this.selectedLayerInfos.blufor.mainBaseLocation.lat, this.selectedLayerInfos.blufor.mainBaseLocation.lng), {
                icon: bluforMainIcon,
                pane: 'objectives'
            })

            var redforMainIcon = new L.DivIcon({
                html: '<img style="height: auto; width: 48px" src="/utils/flags/'+ this.factions[this.selectedLayerInfos.redfor.factionName] + '.png" alt="flag"><img style="height: auto; width: 48px" src="utils/main_icon.png" alt="main">',
                iconSize: [48,72],
                iconAnchor: [24,72],
                className: 'objective-icon'
            })

            var redforMain = new L.Marker(this.coordinatesConverter(this.selectedLayerInfos.redfor.mainBaseLocation.lat, this.selectedLayerInfos.redfor.mainBaseLocation.lng), {
                icon: redforMainIcon,
                pane: 'objectives'
            })

            bluforMain.addTo(this.objectivesLayerGroup)
            redforMain.addTo(this.objectivesLayerGroup)
        },

        /**
         * Adds the flags on the map
         */
        setFlags(){
            var flagsCoords = []

            flagsCoords.push(this.objectivesLayerGroup.getLayers()[0]._latlng)

            this.selectedLayerInfos.objectives.forEach(objective => {

                var objectiveIcon = new L.DivIcon({
                    html: '<p style="margin:0;padding:0">' + objective.name + '</p><img src="/utils/flag_icon.png" alt="' + objective.name + '" style="height: 48px;width: 48px;">',
                    iconSize: [256, 70],
                    iconAnchor: [120,60],
                    className: 'objective-icon'
                })

                var objectiveMarker = new L.Marker(this.coordinatesConverter(objective.lat,objective.lng),{
                    icon: objectiveIcon,
                pane: 'objectives'
                })

                objectiveMarker.addTo(this.objectivesLayerGroup)
                flagsCoords.push(objectiveMarker.getLatLng())
            })

            flagsCoords.push(this.objectivesLayerGroup.getLayers()[1]._latlng)

            var line = new L.Polyline(flagsCoords,{
                fill: false,
                weight: 2,
                color: '#333333',
                pane: 'objectives'
            })

            line.addTo(this.objectivesLayerGroup)
        },

        /**
         * Adds all objectives on the map (mains and flags)
         */
        setObjectives(){

            if (this.objectivesLayerGroup.getLayers().length == 0) {
                this.objectivesLayerGroup.addTo(this.map)
            }
            else{
                this.objectivesLayerGroup.clearLayers()
            }
            
            this.setMains()

            if(this.selectedLayerInfos.objectives.length == 0){
                return
            }

            if(this.selectedLayerInfos.layerName.includes('Territory Control')){
                this.setHexs()
            }
            else{
                this.setFlags()
            }

        },

        /**
         * Specific case for Territory Control to show hexs objectives
         */
        setHexs(){
            this.selectedLayerInfos.objectives.forEach(objective => {

                var hexColor = '#ffffff'

                switch(objective.team){
                    case 1:
                        hexColor = '#0000ff'
                        break   
                    case 2:
                        hexColor = '#ff0000'
                        break               
                    default:
                        break
                }

                var hexSummitsArray = [
                    new L.latLng(this.coordinatesConverter(objective.lat - objective.latExtend, objective.lng - (objective.lngExtend / 2))),
                    new L.latLng(this.coordinatesConverter(objective.lat - objective.latExtend, objective.lng + (objective.lngExtend / 2))),
                    new L.latLng(this.coordinatesConverter(objective.lat, objective.lng + objective.lngExtend)),
                    new L.latLng(this.coordinatesConverter(objective.lat + objective.latExtend, objective.lng + (objective.lngExtend / 2))),
                    new L.latLng(this.coordinatesConverter(objective.lat + objective.latExtend, objective.lng - (objective.lngExtend / 2))),
                    new L.latLng(this.coordinatesConverter(objective.lat, objective.lng - objective.lngExtend)),
                ]

                var hex = new L.Polygon(hexSummitsArray,{
                    color: hexColor,
                    fillColor: hexColor,
                    fillOpacity: 0.5,
                    pane: 'objectives'
                })

                hex.addTo(this.objectivesLayerGroup)
            })
        }
    },
    beforeMount(){

        // Initializing the mortar object
        this.mortar = new Mortar('Mortar')
    },
    mounted(){
        this.setMap()
    },
    watch:{
        
        // Resets the map if the selected map has changed
        selectedMapVue(){
            this.setMap()
        },

        // Resets the objectives and map on layer change
        /*
        selectedLayerInfos(val, oldVal){
            
            if(val.layerMap != oldVal.layerMap){
                this.setMap()
            }

            //this.setObjectives()
        },*/

        // Handles mortar change to redraw it on map, update mortar object and targets
        selectedMortar(val){

            if (this.mortar.isPlaced()) {
                var coords = this.mortar.getCoordinates()

                this.mortar = new Mortar(val, coords, this.selectedMapInfos.size)
                this.redrawMortar()
            }
            else{
                this.mortar = new Mortar(val)
            }
        }
    }
}

</script>

<style scoped>

.leaflet-container{
    z-index: 1;
    background-color: rgb(40, 40, 40);
}
.leaflet-container:hover{
    cursor: pointer;
}

</style>