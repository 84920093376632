<template>
    <div v-show="this.timers.length > 0" id="timer-vue">
        <div class="div-table">
            <div class="thead">
                <div class="data-cell">Vehicle</div>
                <div class="data-cell">Delay</div>
                <div class="data-cell">Remaining time</div>
            </div>
            <div class="tbody">
                <div class="tr" v-for="timer in this.timers" :key="timer">
                    <div class="data-cell">{{ timer.vhl }}</div>
                    <div class="data-cell">{{ timer.delay }}</div>
                    <div class="data-cell">{{ timer.remainingTime }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{

                /**
                 * Array of all the timers sent by right menu
                 * @type {Array<Object>}
                 */
                timers: []
            }
        },
        methods:{

            /**
             * Sets the parameters needed to countdown and puts it into the timers array
             * 
             * @param {Object} object with vehicle and delay
             */
            addTimer(object){

                var timer = {
                    vhl: object.vhl,
                    delay: object.delay,
                    endTime: this.addMinutes(object.delay),
                    remainingTime: ""
                }

                this.timers.push(timer)

                var lastIndex = this.timers.length - 1

                this.remainingTime(timer.endTime, lastIndex)

            },

            /**
             * Returns the time until delay ends
             * 
             * @param {number} delay in minutes
             * @returns {Date} end time of the delay
             */
            addMinutes(delay){
                var now = new Date().getTime()
                return new Date(now + (delay * 60000))
            },

            /**
             * Refreshes the remainingTime attribute of the Object at the given index position in timers Array and alerts the user if ended
             * 
             * @param {Date} endTime the end Date of the delay
             * @param {number} index the index in timers Array to the Object
             */
            remainingTime(endTime, index){

                // Refresh every second
                var interval = setInterval(() => {

                    var now = new Date().getTime()

                    var timeRemaining = endTime - now

                    var minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

                    // Forcing seconds to 2 digits
                    seconds = String(seconds).padStart(2, '0')
                    
                    // alerts user if vehicle timer's finished
                    if(timeRemaining <= 0 ){
                        this.timers[index].remainingTime = "Respawned"
                        alert(this.timers[index].vhl + ' respawned !')
                        clearInterval(interval)

                        // Checks if all timers are over
                        this.checkFinished()
                    }
                    else{
                        this.timers[index].remainingTime = minutes + ':' + seconds
                    }

                }, 1000)
            },

            /**
             * Used to flush the timers Array if all vehicles have respawned
             */
            checkFinished(){
                var counter = 0
                this.timers.forEach((timer) => {
                    if(timer.remainingTime == "Respawned"){
                        counter++
                    }
                })

                if(counter == this.timers.length){
                    this.timers = []
                }
            }
        }
    }
</script>

<style>

#timer-vue{
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  width: 20vw;
  background-color: rgba(20, 20, 20, 0.6);
  backdrop-filter: blur(20px);
  border-top: solid 3px #908F8E;
  border-right: solid 3px #908F8E;
  z-index: 50;
  overflow-y: hidden;
}

#timer-vue *{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#timer-vue *::-webkit-scrollbar{
  display: none;
}

</style>