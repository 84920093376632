<template>
    <div style="position: absolute; top: 0; right: 0; z-index: 1000; font-size: 3rem; padding: 0 1rem; cursor: pointer; color: black;" @click="this.hidden = !this.hidden">&#128712;</div>
    <div id="info-vue" v-show="this.hidden == false">
        <button id="close-button" @click="this.hidden = true">Close</button>
        <h1 style="text-align: center;">Informations</h1>
        <div>
            <h2 style="margin-left: 1rem;">Tutorial :</h2>
            <div style="margin-left: 2rem;">
                <h3>Top menu :</h3>
                <ul style="margin-left: 1rem;">
                    <li>Select map and layer to display both on the map and right menu informations.</li>
                </ul>
            </div>
            <div style="margin-left: 2rem;">
                <h3>Left menu :</h3>
                <ul style="margin-left: 1rem;">
                    <li>Toggle grid, mortar calculator or objectives displayed on the map.</li>
                    <li>Select a mortar to use the calculator, you can reset it by clicking the reset button.</li>
                    <li>Once placed on the map (single click) its coordinates and max range will be shown, in addition to the markers on the map.</li>
                    <li>Every click on the map will add a target and the calculator will tell the settings to apply on the mortar, at the moment height isn't taken into account (further version).</li>
                </ul>
            </div>
            <div style="margin-left: 2rem;">
                <h3>Right menu :</h3>
                <ul style="margin-left: 1rem;">
                    <li>Selected layer informations are displayed here, you can show armored vehicles' weak spots by clicking the vehicles with &#x1F50E;&#xFE0E; icon.</li>
                    <li>You can also set a timer until respawn for every vehicle by clicking on the <img class="timer-icon" style="height: 1rem; width: 1rem;" src="/utils/right/alarm.png"> icon.</li>
                </ul>
            </div>
            <div style="margin-left: 2rem;">
                <h3>Bottom menu :</h3>
                <ul style="margin-left: 1rem;">
                    <li>Left and right menus can be hidden by clicking on the buttons here, mouse coords on the map are also here.</li>
                </ul>
            </div>
            <div style="margin-left: 2rem;">
                <h3>Map :</h3>
                <ul style="margin-left: 1rem;">
                    <li>Click on the map once to place your mortar, then every click will add a target with elevation and azimuth to hit it, according to the mortar type selected in the left menu.</li>
                    <li>The red circle shows the minimum distance to shoot at from the mortar, the black one shows maximum range (calculated)</li>
                    <li>Mortar isn't draggable to avoid mistakes, target can be dragged to another location. Clicking on a target will delete it.</li>
                </ul>
            </div>
        </div>
        <div>
            <h2 style="margin-left: 1rem;">Links :</h2>
            <ul>
                <li><img style="height: 1rem; width: 1rem;" src="/utils/info/github.png" alt="GitHub"> link coming soon...</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            
            /**
             * True to hide this element, false otherwise
             * @type {boolean}
             */
            hidden: true
        }
    }
}
</script>

<style scoped>

#info-vue{
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
  padding: 1rem;
  width: 90vw;
  height: 90vh;
  transform: translate(-50%, -50%);
  background-color: rgba(20, 20, 20, 0.6);
  backdrop-filter: blur(20px);
  border: solid 3px #908F8E;
  overflow-y: scroll;
}

</style>