<template>
  <div v-if="!this.isMobile() || !this.displayMobile">
    
    <top-menu 
    ref="topMenu" 
    :maps="maps" 
    :layers="layers" 
    @changeMap="changeMap" 
    @changeLayer="changeLayer" 
    @toggleGrid="toggleGrid" 
    @toggleObjectives="toggleObjectives" 
    @toggleMortar="toggleMortar"></top-menu>
    
    <Transition appear name="left">
      <left-menu 
      v-show="showLeftMenu" 
      ref="leftMenu" 
      :mortarTypes="mortarTypes"
      @placeMortar="placeMortar"
      @freezeMortar="freezeMortar"
      @resetMortar="resetMortar" 
      @resetTargets="resetTargets" 
      @deleteMarker="deleteMarker" 
      @setMortarType="setMortarType"></left-menu>
    </Transition>
    
    <map-vue 
    ref="map" 
    :selected-map-vue="selectedMap" 
    :selected-layer-infos="selectedLayerInfos" 
    :selected-map-infos="selectedMapInfos" 
    @targetArray="sendTargetArray" 
    @mouseCoords="setMouseCoordinates" 
    @mortar="sendMortarInfos"></map-vue>
    
    <Transition appear name="right">
      <right-menu 
      v-show="showRightMenu" 
      ref="rightMenu" 
      :mapNameLanes="selectedMapLaneName" 
      :layer-infos="selectedLayerInfos" 
      @showWeakSpots="showWeakSpots" 
      @addTimer="addTimer"></right-menu>
    </Transition>
    
    <Transition name="weak-spots">
      <weak-spots 
      ref="weakSpots" 
      v-show="showWeakSpotsModal" 
      @closeWeakSpots="closeWeakSpots"></weak-spots>
    </Transition>
    
    <timer-vue ref="timerVue"></timer-vue>
    
    <bottom-menu ref="bottomMenu" 
    @toggleRightMenu="toggleRightMenu" 
    @toggleLeftMenu="toggleLeftMenu"></bottom-menu>
    
    <info-vue ref="infoVue"></info-vue>
  
  </div>
  <div v-if="this.isMobile() || this.displayMobile">

    <button 
    style="position: absolute; top: 0; left: 0; width: 15vw; height: 15vw; z-index: 9999; padding: 1vh;" 
    @click="this.mobileOpenLeftMenu">
      <img 
      style="height: auto; width: 8vw;" 
      src="utils/left/mortar-menu.png" 
      alt="Mortar">
    </button>
    <button 
    style="position: absolute; top: 0; right: 0; width: 15vw; height: 15vw; z-index: 9999; padding: 1vh;" 
    @click="this.mobileOpenRightMenu">&#9776;</button>
    <button 
    style="position: absolute; bottom: 0; right: 0; width: 15vw; height: 15vw; z-index: 9999; padding: 1vh;" 
    @click="this.mobileOpenMapSettings">
      <img style="height: auto; width: 8vw;" 
      src="utils/settings.png" 
      alt="Set.">
    </button>

    <map-vue 
    ref="map" 
    :selected-map-vue="selectedMap" 
    :selected-layer-infos="selectedLayerInfos" 
    :selected-map-infos="selectedMapInfos" 
    @targetArray="mobileSendTargetArray" 
    @mortar="mobileSendMortarInfos"></map-vue>
    
    <Transition appear name="left">
      <MobileLeftMenuVue 
      v-show="showMobileLeftMenu" 
      ref="mobileLeftMenu" 
      :mortarTypes="mortarTypes" 
      @closeMobileLeftMenu="mobileCloseLeftMenu" 
      @resetMortar="resetMortar" 
      @resetTargets="resetTargets" 
      @deleteMarker="deleteMarker" 
      @setMortarType="setMortarType"></MobileLeftMenuVue>
    </Transition>

    <Transition appear name="right">
      <MobileRightMenuVue 
      v-show="showMobileRightMenu" 
      ref="mobileRightMenu"
      :maps="maps" 
      :layers="layers" 
      :mapNameLanes="selectedMapLaneName" 
      :layer-infos="selectedLayerInfos"
      @closeMobileRightMenu="mobileCloseRightMenu" 
      @changeMap="changeMap" 
      @changeLayer="changeLayer"></MobileRightMenuVue>
    </Transition>

    <Transition name="bottom">
      <MobileMapSettingsVue 
      v-show="showMobileMapSettings" 
      ref="mobileMapSettings"
      @closeMobileMapSettings="mobileCloseMapSettings" 
      @toggleGrid="toggleGrid" 
      @toggleObjectives="toggleObjectives" 
      @toggleMortar="toggleMortar"></MobileMapSettingsVue>
    </Transition>

  </div>
</template>

<script>
import MapVue from "./components/MapVue.vue"
import TopMenu from "./components/TopMenu.vue"
import LeftMenu from "./components/LeftMenu.vue"
import RightMenu from './components/RightMenu.vue'
import WeakSpots from "./components/WeakSpots.vue"
import TimerVue from "./components/TimerVue.vue"
import BottomMenu from "./components/BottomMenu.vue"
import InfoVue from "./components/InfoVue.vue"
import MobileLeftMenuVue from "./components/MobileLeftMenu.vue"
import MobileRightMenuVue from "./components/MobileRightMenu.vue"
import MobileMapSettingsVue from "./components/MobileMapSettings.vue"
import { getMaps, getLayers, getLayerInfos, getMapInfos } from "./assets/LoadMaps"
import { getMortarTypes } from './assets/Mortar.js'

export default {
  name: 'App',
  components: {
    MapVue,
    TopMenu,
    LeftMenu,
    RightMenu,
    WeakSpots,
    TimerVue,
    BottomMenu,
    InfoVue,
    MobileLeftMenuVue,
    MobileRightMenuVue,
    MobileMapSettingsVue
  },
  data() {
    return {

      /**
       * Contains the map names
       * @type {Object}
       */
      maps: {},

      /**
       * Contains the layers names for the selected map
       * @type {Array}
       */
      layers: [],

      /**
       * Contains the faction names
       * @type {Object}
       */
       factions: {},

       /**
       * Contains the layers names for the selected map
       * @type {Array}
       */
       setups: [],

      /**
       * Currently selected map, used in the selectMap select tag
       * @type {String}
       */
      selectedMap: '',

      /**
       * Currently selected layer, used the selectLayer select tag
       * @type {String}
       */
      selectedLayer: '',

      /**
       * Contains the selected layer data (factions, vehicles, etc...)
       * @type {Object}
       */
      selectedLayerInfos : {},

      /**
       * Contains the selected map data (size, name, etc...)
       * @type {Object}
       */
      selectedMapInfos: {},

      /**
       * Contains the string used in the link to squadlanes' corresponding URL's name
       * @type {String}
       */
      selectedMapLaneName: "Al+Basrah",

      /**
       * Contains the list of available mortars in the game
       * @type {Array<String>}
       */
      mortarTypes: [],

      /**
       * Condition to show or hide the weakspots modal
       * @type {boolean}
       */
      showWeakSpotsModal: false,

      /**
       * Depending on current display, true if mobile, false otherwise
       * @type {boolean}
       */
      displayMobile: false,

      /**
       * Condition to show or hide the left menu
       * @type {boolean}
       */
      showLeftMenu: true,

      /**
       * Condition to show or hide the right menu
       * @type {boolean}
       */
      showRightMenu: true,

      /**
       * Condition to show or hide the left menu on mobile app
       * @type {boolean}
       */
      showMobileLeftMenu: false,

      /**
       * Condition to show or hide the right menu on mobile app
       * @type {boolean}
       */
      showMobileRightMenu: false,

      /**
       * Condition to show or hide the settings menu on mobile app
       * @type {boolean}
       */
      showMobileMapSettings: false
    };
  },
  beforeMount(){ 

    // Initializing the app
    this.init()

    // Render as mobile app if on mobile
    if (this.isMobile()) {
      this.displayMobile = true
    }
  },
  methods:{

    /**
     * Setting up every variables and objects
     */
    init(){

      // Getting maps list
      this.maps = getMaps()
      console.log(this.maps);

      // Setting 1st map as default displayed
      this.selectedMap = Object.keys(this.maps)[0]

      console.log(this.selectedMap);

      // Getting layers for the 1st map
      this.layers = getLayers(this.selectedMap)
      
      // Setting 1st layer as default displayed
      this.changeLayer(this.layers[0])

      // Getting map informations used in right menu and map
      this.changeMapInfos()

      // Getting the mortars list
      this.mortarTypes = getMortarTypes()
      
      // On resize checks if mobile displayed should be used instead of desktop
      window.addEventListener('resize', this.windowResize)
    },

    /**
     * Sets displayMobile according to aspect ratio changes
     */
    windowResize(){

      // Setting to true only if app is not in mobile display already
      if (this.isMobile() && !this.displayMobile) {
        //console.log('resized to mobile !');
        this.displayMobile = true
      }
      // Setting to false only if app is not on desktop display already
      else if (!this.isMobile() && this.displayMobile) {
        //console.log('resized to desktop !');
        this.displayMobile = false
      }
    },
    /**
     * Checks aspect ratio of the viewport
     * @returns {boolean} : True if mobile, false otherwise
     */
    isMobile(){
      return window.innerHeight > window.innerWidth ? true : false
    },

    /**
     * Setting variables according to the newly selected map
     * @param {String} newMap : The new map selected
     */
    changeMap(newMap){

      this.selectedMap = newMap.toString()

      this.layers = getLayers(this.selectedMap)

      this.selectedLayer = this.layers[0]

      this.changeLayer(this.selectedLayer)
      this.changeMapInfos()
      this.selectedMapLaneName = this.selectedMapInfos.lanesTitle
    },

    /**
     * Settings variables according to the newly selected layer
     * @param {String} newLayer : The new layer selected
     */
    changeLayer(newLayer){

      this.selectedLayer = newLayer.toString()

      let layerName = this.selectedMap + "_" + this.selectedLayer
      layerName = layerName.replaceAll(" ","_")

      console.log(layerName);

      this.selectedLayerInfos = getLayerInfos(layerName)
    },

    /**
     * Sends the target array emitted by the map to the left menu
     * @param {Array<Object>} array : The array containing every targets data
     */
    sendTargetArray(array){

      this.$refs.leftMenu.targetArray = array

      //console.log(this.$refs.leftMenu.targetArray);

    },

    /**
     * Setting the showMobileLeftMenu variable to true so left menu is shown (mobile)
     */
    mobileOpenLeftMenu(){
      this.showMobileLeftMenu = true
    },
    
    /**
     * Setting the showMobileLeftMenu variable to false so left menu is hidden (mobile)
     */
    mobileCloseLeftMenu(){
      this.showMobileLeftMenu = false
    },
    
    /**
     * Setting the showMobileRightMenu variable to true so right menu is shown (mobile)
     */
    mobileOpenRightMenu(){
      this.showMobileRightMenu = true
    },
    
    /**
     * Setting the showMobileRightMenu variable to false so right menu is hidden (mobile)
     */
    mobileCloseRightMenu(){
      this.showMobileRightMenu = false
    },
    
    /**
     * Setting the showMobileMapSettings variable to true so settings menu is shown (mobile)
     */
    mobileOpenMapSettings(){
      this.showMobileMapSettings = true
    },
    
    /**
     * Setting the showMobileMapSettings variable to false so settings menu is hidden (mobile)
     */
    mobileCloseMapSettings(){
      this.showMobileMapSettings = false
    },

    /**
     * Sends the target array emitted by the map to the left menu (mobile)
     * @param {Array<Object>} array : The array containing every targets data
     */
    mobileSendTargetArray(array){
      this.$refs.mobileLeftMenu.targetArray = array
    },

    /**
     * Sends the selected mortar coordinates emitted by the map to the left menu
     * @param {Object} mortar : Mortar coordinates
     */
    sendMortarInfos(mortar){
      this.$refs.leftMenu.mortarCoords = mortar
      //console.log(mortar);
    },

    /**
     * Sends the selected mortar coordinates emitted by the map to the left menu (mobile)
     * @param {Object} mortar : Mortar coordinates
     */
    mobileSendMortarInfos(mortar){
      this.$refs.mobileLeftMenu.mortarCoords = mortar
    },

    /**
     * Updates the map informations variable
     */
    changeMapInfos(){
      this.selectedMapInfos = getMapInfos(this.selectedMap)
      //console.log(this.selectedMapInfos)
    },

    /**
     * Sends mouse coordinates to bottom menu
     * @param {Object} coords : Mouse coordinates
     */
    setMouseCoordinates(coords){
      this.$refs.bottomMenu.mouseCoords = coords
    },

    /**
     * Calls toggleGrid function on map child component
     */
    toggleGrid(){
      this.$refs.map.toggleGrid()
    },

    /**
     * Calls toggleObjectives function on map child component
     */
    toggleObjectives(){
      this.$refs.map.toggleObjectives()
    },

    /**
     * Changes map child variable mortarActive value to the given one from left menu
     * @param {boolean} bool : True if mortar placement button is selected, false otherwise
     */
     placeMortar(bool){
      console.log("Place Mortar value : ");
      console.log(bool);
      this.$refs.map.mortarActive = bool
    },

    /**
     * Changes map child variable mortarActive value to the given one from left menu
     * @param {bool} bool : True if mortar is frozen, false otherwise
     */
    freezeMortar(bool){
      this.$refs.map.mortarActive = !bool
      console.log(this.$refs.map.mortarActive);
    },

    /**
     * Calls resetMortar function on map child component
     */
    resetMortar(){
      this.$refs.map.resetMortar()
    },

    /**
     * Calls resetTargets function on map child component
     */
    resetTargets(){
      this.$refs.map.resetTargets()
    },

    /**
     * Calls toggleMortar function on map child component
     */
    toggleMortar(){
      this.$refs.map.toggleMortar()
    },

    /**
     * Calls removeMarkerById function on map child component
     * @param {integer} id : Marker's id to remove
     */
    deleteMarker(id){
      this.$refs.map.removeMarkerById(id)
    },

    /**
     * Displays weakSpots modal with selected vehicle data (name and folder)
     * @param {String} vhl : Vehicle name
     * @param {String} vhlFolder : URI to the vehicle folder
     */
    showWeakSpots(vhl, vhlFolder){
      this.$refs.weakSpots.vehicle = vhl
      this.$refs.weakSpots.vehicleFolder = vhlFolder
      this.showWeakSpotsModal = true
    },

    /**
     * Closes weakspots modal
     */
    closeWeakSpots(){
      if (this.showWeakSpotsModal == true) {
        this.showWeakSpotsModal = false
      }
    },

    /**
     * Sends to map child component the selected mortar
     * @param {String} moType : Selected mortar typeoile"
     */
    setMortarType(moType){
      this.$refs.map.selectedMortar = moType
    },

    /**
     * Sends a timer object to timerVue child
     * @param {Object} timer : the timer to send
     */
    addTimer(timer){
      this.$refs.timerVue.addTimer(timer)
    },

    /**
     * Toggles showLeftMenu variable to show or hide left menu
     */
    toggleLeftMenu(){
      this.showLeftMenu = !this.showLeftMenu
    },

    /**
     * Toggles showRightMenu variable to show or hide right menu
     */
    toggleRightMenu(){
      this.showRightMenu = !this.showRightMenu
    }
  }
};
</script>

<style>
  
@font-face {
  font-family: 'Roboto';
  src: url('../public/utils/font/Roboto-Regular.ttf');
}

@media (min-width: 0) and (orientation: landscape) {

  html, body{
    margin: 0;
    padding: 0;
    background-color: rgba(20, 20, 20, 1);
    font-family: 'Roboto';
    font-size: 1.5vh;
    color: white;
    overflow: hidden;
  }

  button{
    font-size: 1.5vh;
    background-color: rgb(255, 193, 7);
    font-family: 'Roboto';
    cursor: pointer;
    padding: 0.1vh;
    border: solid 2px lightslategray;
    transition: 0.2s ease-in-out;
  }

  button:hover{
    background-color: rgb(204, 158, 4);
  }

  .active{
    background-color: rgb(20, 20, 20);
    color: white;
  }

  .active:hover{
    background-color: rgb(10, 10, 10);
  }

  select, select option{
    font-family: 'Roboto', sans-serif;
    padding: 0.25rem;
  }

  .leaflet-control-attribution{
    display: none;
  }

  #close-button{
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 2vh;
  }

  #map-settings > *, #mortar-settings > *, #left-menu-coords > *{
    margin: 0.5rem;
  }

  .div-table{
    width: 100%;
    overflow-y: auto;
    max-height: 20vh;
    text-align: center;
  }

  .div-table .thead, .div-table .tbody .tr {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    border-bottom: solid 1px white;
  }

  .div-table .thead .data-cell, .div-table .tbody .tr .data-cell{
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div-table .delete-marker{
    flex-basis: 20%;
    cursor: pointer;
  }

  .mortar-icon{
    cursor: pointer;
  }

  .mortar-target-icon{
    background-color: transparent;
    background: transparent;
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 900;
    font-size: 1.7vh;
    -webkit-text-stroke: 1px black;
    text-align: center;
    z-index: 1000;
  }

  .mortar-target-icon:hover{
    cursor: grab;
  }

  .objective-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: 900;
    font-size: 2vh;
    -webkit-text-stroke: 1px black;
    z-index: 600;
  }

  .leaflet-marker-icon{
    display: flex;
    text-align: center;
  }

  .map-grid-icons{
    background-color: transparent;
    border: none;
    font-family: 'Roboto';
    display: flex;
    font-size: 1.2vh;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .objective-icon img{
    width: 48px;
    height: auto;
  }

  .vertical-center{
    top: 50%;
    transform: translateY(-50%);
  }

  .horizontal-center{
    left: 50%;
    transform: translateX(-50%);
  }

  .full-center{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .left-enter-active,
  .left-leave-active,
  .right-enter-active,
  .right-leave-active,
  .weak-spots-enter-active,
  .weak-spots-leave-active{
    transition: .5s ease-in-out;
  }

  .left-enter-to,
  .left-leave-from,
  .right-enter-to,
  .right-leave-from{
    top: 50%;
    transform: translate(0,-50%);
  }

  .left-leave-to,
  .left-enter-from{
    top: 50%;
    transform: translate(-100%, -50%);
  }

  .right-leave-to,
  .right-enter-from{
    top: 50%;
    transform: translate(100%, -50%);
  }

  .weak-spots-enter-to,
  .weak-spots-leave-from{
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .weak-spots-enter-from,
  .weak-spots-leave-to{
    left: 50%;
    transform: translate(-50%, 100%);
  }
}

@media (orientation:portrait){
  
  html, body{
    margin: 0;
    padding: 0;
    background-color: rgba(20, 20, 20, 1);
    font-family: 'Roboto';
    font-size: 3vh;
    color: white;
    overflow: hidden;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }

  button{
    font-size: 3vh;
    background-color: rgba(20, 20, 20, 0.6);
    backdrop-filter: blur(20px);
    border: none;
    font-family: 'Roboto';
    color: white;
    cursor: pointer;
    padding: 0.1vh;
    transition: 0.2s ease-in-out;
  }

  .active{
    background-color: rgb(20, 20, 20);
    color: white;
  }

  .active:hover{
    background-color: rgb(10, 10, 10);
  }

  select, select option{
    font-family: 'Roboto', sans-serif;
    padding: 0.25rem;
  }

  .leaflet-control-attribution{
    display: none;
  }

  .leaflet-control-zoom{
    display: none;
  }
  
  #map-settings > *, #mortar-settings > *, #left-menu-coords > *{
    margin: 0.5rem;
  }

  .div-table{
    width: 100%;
    overflow-y: auto;
    text-align: center;
  }

  .div-table .thead, .div-table .tbody .tr {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    border-bottom: solid 1px white;
  }

  .div-table .thead .data-cell, .div-table .tbody .tr .data-cell{
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div-table .delete-marker{
    flex-basis: 20%;
    cursor: pointer;
  }

  .mortar-icon{
    cursor: pointer;
  }

  .mortar-target-icon{
    background-color: transparent;
    background: transparent;
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 900;
    font-size: 1.7vh;
    -webkit-text-stroke: 1px black;
    text-align: center;
    z-index: 1000;
  }

  .mortar-target-icon:hover{
    cursor: grab;
  }

  .objective-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: 900;
    font-size: 2vh;
    -webkit-text-stroke: 1px black;
    z-index: 600;
  }

  .leaflet-marker-icon{
    display: flex;
    text-align: center;
  }

  .map-grid-icons{
    background-color: transparent;
    border: none;
    font-family: 'Roboto';
    display: flex;
    font-size: 1.2vh;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .objective-icon img{
    width: 48px;
    height: auto;
  }

  .vertical-center{
    top: 50%;
    transform: translateY(-50%);
  }

  .horizontal-center{
    left: 50%;
    transform: translateX(-50%);
  }

  .full-center{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .left-enter-active,
  .left-leave-active,
  .right-enter-active,
  .right-leave-active,
  .weak-spots-enter-active,
  .weak-spots-leave-active,
  .bottom-enter-active,
  .bottom-leave-active{
    transition: .5s ease-in-out;
  }

  .left-enter-to,
  .left-leave-from,
  .right-enter-to,
  .right-leave-from{
    transform: translateX(0)
  }

  .left-leave-to,
  .left-enter-from{
    transform: translateX(-100%);
  }

  .right-leave-to,
  .right-enter-from{
    transform: translateX(100%);
  }

  .weak-spots-enter-to,
  .weak-spots-leave-from{
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .weak-spots-enter-from,
  .weak-spots-leave-to{
    left: 50%;
    transform: translate(-50%, 100%);
  }

  .bottom-enter-to,
  .bottom-leave-from{
    transform: translateY(0);
  }

  .bottom-enter-from,
  .bottom-leave-to{
    transform: translateY(100%);
  }
}

</style>
