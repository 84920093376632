import * as Vue from 'vue'
import App from './App.vue'
import 'leaflet/dist/leaflet.css';

const app = Vue.createApp(App)

app.mount('#app')

/*
createApp(App)
.mount('#app')
*/
