<template>
    <div id="right-menu" class="vertical-center" style="text-align: center;">
        <h1>{{ layerInfos.gamemode }} v{{ layerInfos.version }}</h1>
        <h3><nobr>{{ layerInfos.weather }} - {{ layerInfos.size }} layer<p v-if="this.layerInfos.hasCommander">Commander available</p><p v-else>No commander</p></nobr></h3>
        <h3>{{ this.layerInfos.team1Tickets }}<img data-v-d8df1bf2="" src="/utils/right/ticket.png" alt=" tickets" style="max-height: 1rem; width: auto; margin-left: 0.5rem;"> - {{ this.layerInfos.team2Tickets }}<img data-v-d8df1bf2="" src="/utils/right/ticket.png" alt=" tickets" style="max-height: 1rem; width: auto; margin-left: 0.5rem;"></h3>
        <!--<h4 v-if="isLanes()"><a :href="getSquadLanesURL()" target="_blank">SquadLanes link</a></h4>-->

        <div class="faction-selection" style="margin-bottom: 2vh;">
            <div class="faction-selection-selects">
                <label for="team1" style="font-size: 2vh;">Team 1 Faction :</label>
                <select name="team1" id="select-team1" style="flex-basis: 50%;" v-model="this.team1" @change="this.team1Change">
                    <option value="" selected>Select a faction...</option>
                    <option v-for="(faction) of this.availableTeam1" :key="faction" :value="faction">{{ faction }}</option>
                </select>
            </div>
            <div>vs</div>
            <div class="faction-selection-selects">
                <label for="team2" style="font-size: 2vh;">Team 2 Faction :</label>
                <select name="team2" id="select-team2" style="flex-basis: 50%;" v-model="this.team2" @change="this.team2Change">
                    <option value="" selected>Select a faction...</option>
                    <option v-for="(faction) of this.availableTeam2" :key="faction" :value="faction">{{ faction }}</option>
                </select>
            </div>
        </div>

        <div class="faction-infos">
            <div class="right-expandable">
                <div class="flexbox-row" @click="toggleTeam1" style="cursor: pointer;">
                    <h3 class="faction-header"><img class="faction-flag" :src="this.team1Icon" alt="Team 1">{{ this.team1Faction.name }}</h3>
                    <div class="dropdown-btn" v-bind:class="{'dropdown-active': showTeam1}">&#8964;</div>
                </div>
                <Transition name="dropdown">
                    <div v-if="this.showTeam1">
                        <div class="flexbox-row" style="justify-content: center;">
                            <label for="team1-setup-select">Faction setup :</label>
                            <select name="team1-setup-select" id="team1-setup-select" v-model="this.team1Setup">
                                <option disabled value="" selected>Select a setup</option>
                                <option v-for="(setup) of this.availableTeam1Setups" :key="setup" :value="setup">{{ setup }}</option>
                            </select>
                        </div>
                        <h3 v-if="this.team1Setup != ''">{{ this.team1SetupName }}</h3>
                        <div v-if="this.team1Setup != ''">
                            <div v-if="this.team1SetupObject.specials.length != 0" style="text-wrap: nowrap;">
                                <p v-for="spec in this.team1SetupObject.specials" :key="spec">{{ spec }}</p>
                            </div>
                            <div v-for="(vhls, category) of this.team1SetupObject" :key="category">
                                <h4 v-if="category != 'specials'">{{ this.categories[category] }}</h4>
                                <div class="div-table" style="max-height: none;" v-if="category != 'fobEmplacements' && category != 'specials'">
                                    <div class="thead">
                                        <div class="data-cell" style="flex-basis: 20%;"></div>
                                        <div class="data-cell" style="flex-basis: 100%;">Name</div>
                                        <div class="data-cell" style="flex-basis: 20%;">#</div>
                                        <div class="data-cell" style="flex-basis: 20%;">Delay</div>
                                        <div class="data-cell" style="flex-basis: 20%;">Resp.</div>
                                    </div>
                                    <div class="tbody">
                                        <div class="tr" v-for="vhl of vhls" :key="vhl">
                                            <div class="data-cell" style="flex-basis: 20%;"><img :src="'/utils/vehicles/'+vhl.icon+'.png'" alt="icon" style="height: 3vh; width: auto;"></div>
                                            <div class="data-cell" style="flex-basis: 100%; display: flex; justify-content: left;" v-if="this.weakSpots[vhl.name] != undefined"><a @click="showWeakSpots(vhl.name)">&#x1F50E;&#xFE0E; {{ vhl.name }}</a></div>
                                            <div class="data-cell" style="flex-basis: 100%; display: flex; justify-content: left;" v-else>{{ vhl.name }}</div>
                                            <div class="data-cell" style="flex-basis: 20%;">x{{ vhl.count }}</div>
                                            <div class="data-cell" style="flex-basis: 20%;">{{ vhl.delay }} '</div>
                                            <div class="data-cell" style="flex-basis: 20%;">{{ vhl.respawnTime }} '</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="category == 'fobEmplacements' && vhls.length != 0" class="two-columns-flexbox">
                                    <div v-for="emplacement in vhls" :key="emplacement"><img :src="'/utils/right/'+emplacement.icon+'.png'" alt="icon" style="height: 3vh; width: auto;">{{ emplacement.name }} x{{ emplacement.qty }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
            <div class="right-expandable">
                <div class="flexbox-row" @click="toggleTeam2" style="cursor: pointer;">
                    <h3 class="faction-header"><img class="faction-flag" :src="this.team2Icon" alt="Team 2">{{ this.team2Faction.name }}</h3>
                    <div class="dropdown-btn" v-bind:class="{'dropdown-active': showTeam2}">&#8964;</div>
                </div>
                <Transition name="dropdown">
                    <div v-if="this.showTeam2">
                        <div class="flexbox-row" style="justify-content: center;">
                            <label for="team2-setup-select">Faction setup :</label>
                            <select name="team2-setup-select" id="team2-setup-select" v-model="this.team2Setup">
                                <option disabled value="" selected>Select a setup</option>
                                <option v-for="(setup) of this.availableTeam2Setups" :key="setup" :value="setup">{{ setup }}</option>
                            </select>
                        </div>
                        <h3 v-if="this.team2Setup != ''">{{ this.team2SetupName }}</h3>
                        <div v-if="this.team2Setup != ''">
                            <div v-if="this.team2SetupObject.specials.length != 0" style="text-wrap: nowrap;">
                                <p v-for="spec in this.team2SetupObject.specials" :key="spec">{{ spec }}</p>
                            </div>
                            <div v-for="(vhls, category) of this.team2SetupObject" :key="category">
                                <h4 v-if="category != 'specials'">{{ this.categories[category] }}</h4>
                                <div class="div-table" style="max-height: none;" v-if="category != 'fobEmplacements' && category != 'specials'">
                                    <div class="thead">
                                        <div class="data-cell" style="flex-basis: 20%;"></div>
                                        <div class="data-cell" style="flex-basis: 100%;">Name</div>
                                        <div class="data-cell" style="flex-basis: 20%;">#</div>
                                        <div class="data-cell" style="flex-basis: 20%;">Delay</div>
                                        <div class="data-cell" style="flex-basis: 20%;">Resp.</div>
                                    </div>
                                    <div class="tbody">
                                        <div class="tr" v-for="vhl of vhls" :key="vhl">
                                            <div class="data-cell" style="flex-basis: 20%;"><img :src="'/utils/vehicles/'+vhl.icon+'.png'" alt="icon" style="height: 3vh; width: auto;"></div>
                                            <div class="data-cell" style="flex-basis: 100%; display: flex; justify-content: left;" v-if="this.weakSpots[vhl.name] != undefined"><a @click="showWeakSpots(vhl.name)">&#x1F50E;&#xFE0E; {{ vhl.name }}</a></div>
                                            <div class="data-cell" style="flex-basis: 100%; display: flex; justify-content: left;" v-else>{{ vhl.name }}</div>
                                            <div class="data-cell" style="flex-basis: 20%;">x{{ vhl.count }}</div>
                                            <div class="data-cell" style="flex-basis: 20%;">{{ vhl.delay }} '</div>
                                            <div class="data-cell" style="flex-basis: 20%;">{{ vhl.respawnTime }} '</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="category == 'fobEmplacements' && vhls.length != 0" class="two-columns-flexbox">
                                    <div v-for="emplacement in vhls" :key="emplacement"><img :src="'/utils/right/'+emplacement.icon+'.png'" alt="icon" style="height: 3vh; width: auto;">{{ emplacement.name }} x{{ emplacement.qty }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
        <!--
        <div class="faction-infos" id="blufor">
            <img :src="this.factionIcon[0]" :alt="layerInfos.blufor.factionName">
            <h3>{{ layerInfos.blufor.factionName }}</h3>
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
                <h4 style="margin: 0; padding: 0;">{{ layerInfos.blufor.tickets }}</h4>
                <img src="/utils/right/ticket.png" alt=" tickets" style="max-height: 1rem; width: auto; margin-left: 0.5rem;">
            </div>

            <table style="text-align: center;">
                <thead>
                    <th></th>
                    <th>Name</th>
                    <th>#</th>
                    <th><img style="height: 1rem; width: auto;" src="/utils/right/clock.png" alt="Delay"></th>
                    <th>Resp.</th>
                    <th>Set timer</th>
                </thead>
                <tbody>
                    <tr v-for="vhl in layerInfos.blufor.vehicles" :key="vhl">
                        <td><img :src="getVehicleIcon(vhl.icon)" :alt="vhl.icon"></td>
                        <td v-if="this.weakSpots[vhl.name] != undefined" class="icon-name">
                            <a @click="showWeakSpots(vhl.name)">&#x1F50E;&#xFE0E; {{ vhl.name }}</a>
                        </td>
                        <td v-else class="icon-name">{{ vhl.name }}</td>
                        <td>{{ vhl.count }}</td>
                        <td>{{ vhl.delay }}'</td>
                        <td v-if="vhl.respawnTime != 0">{{ vhl.respawnTime }}'</td>
                        <td v-else>No resp.</td>
                        <td v-if="vhl.respawnTime != 0"><img class="timer-icon" style="height: 1rem; width: 1rem;" src="/utils/right/alarm.png" alt="Set" @click="this.addTimer(vhl.name,vhl.respawnTime)"></td>
                        <td v-else></td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        

        <div class="faction-infos" id="redfor">
            <img :src="this.factionIcon[1]" :alt="layerInfos.redfor.factionName">
            <h3>{{ layerInfos.redfor.factionName }}</h3>
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
                <h4 style="margin: 0; padding: 0;">{{ layerInfos.redfor.tickets }}</h4>
                <img src="/utils/right/ticket.png" alt=" tickets" style="max-height: 1rem; width: auto; margin-left: 0.5rem;">
            </div>
            <table style="text-align: center;">
                <thead>
                    <th></th>
                    <th>Name</th>
                    <th>#</th>
                    <th><img style="height: 1rem; width: auto;" src="/utils/right/clock.png" alt="Delay"></th>
                    <th>Resp.</th>
                    <th>Set timer</th>
                </thead>
                <tbody>
                    <tr v-for="vhl in layerInfos.redfor.vehicles" :key="vhl">
                        <td><img :src="getVehicleIcon(vhl.icon)" :alt="vhl.icon"></td>
                        <td v-if="this.weakSpots[vhl.name] != undefined" class="icon-name">
                            <a @click="showWeakSpots(vhl.name)">&#x1F50E;&#xFE0E; {{ vhl.name }}</a>
                        </td>
                        <td v-else class="icon-name">{{ vhl.name }}</td>
                        <td>{{ vhl.count }}</td>
                        <td>{{ vhl.delay }}'</td>
                        <td v-if="vhl.respawnTime != 0">{{ vhl.respawnTime }}'</td>
                        <td v-else>No resp.</td>
                        <td v-if="vhl.respawnTime != 0"><img class="timer-icon" style="height: 1rem; width: 1rem;" src="/utils/right/alarm.png" alt="Set" @click="this.addTimer(vhl.name,vhl.respawnTime)"></td>
                        <td v-else></td>
                    </tr>
                </tbody>
            </table>
        </div>-->
    </div>
</template>

<script>
import factions  from "../assets/data/factions.json";
import units from "../assets/data/units.json"

export default{

    data(){
        return{

            /**
             * Contains translations for JSON objects keys to readable text
             */
            categories:{
                "vehicles":"Vehicles",
                "forward":"Forward spawn (no respawn)",
                "tanks":"Tanks",
                "heli":"Helis (if available)",
                "boats":"Boats (if available)",
                "specials":"Specials",
                "fobEmplacements":"FOB Emplacements"
            },

            /**
             * Contains the translation from Squad's vehicles names to simpler names used on the app
             * @type {Object}
             */
            weakSpots:{
                "ASLAV":"aslav",
                "BMD-1M":"bmd1",
                "BMD-4M":"bmd4",
                "BMP-1":"bmp1",
                "BMP-1 ZU-23-2":"bmp1",
                "BMP-2":"bmp2",
                "BRDM-2":"brdm2",
                "BRDM-2 S8":"brdm2",
                "BRDM-2 Spandrel":"brdm2",
                "BTR-80":"btr80",
                "BTR-82A":"btr82",
                "BTR-D":"btrd",
                "BTR-D Kord":"btrd",
                "BTR-D PKM":"btrd",
                "BTR-DG Logistics":"btrd",
                "BTR-MDM":"btrmdm",
                "BTR-ZD":"btrd",
                "Coyote":"coyote",
                "FV107":"fv107",
                "FV4034":"fv4034",
                "FV432":"fv432",
                "FV432 RWS":"fv432",
                "FV510":"fv510",
                "FV510 UA":"fv510",
                "FV520 CTAS40":"fv520",
                "LAV 6":"lav6",
                "LAV III C6 RWS":"aslav",
                "LAV III M2 RWS":"aslav",
                "LAV-25":"aslav",
                "Leopard 2A6M CAN":"2a6",
                "M1126 CROWS M2":"stryker",
                "M1126 CROWS M240":"stryker",
                "M113A3 C6":"tlav",
                "M113A3 M2":"tlav",
                "M113A3 TLAV":"tlav",
                "M1A1":"m1a1",
                "M1A2":"m1a2",
                "M2A3":"m2a3",
                "MT-LB Logistics":"mtlb",
                "MT-LB PKT":"mtlb",
                "MT-LB VMK":"mtlb",
                "MT-LB ZU-23-2":"mtlb",
                "MT-LBM 6MA":"mtlb",
                "MT-LBM 6MA S8":"mtlb",
                "MT-LBM 6MB":"mtlb",
                "Sprut-SDM1":"sprut",
                "T-62":"t62",
                "T-72B3":"t72b3",
                "T-72S":"t72s",
                "TAPV C6":"tapv",
                "TAPV M2":"tapv",
                "ZBD04A":"zbd04",
                "ZBD05":"zbd05",
                "ZBD05 HJ73C":"zbd05",
                "ZBL08":"zbl08",
                "ZBL08 HJ73C":"zbl08",
                "ZSD05":"zsd05",
                "ZSD05 Logistics":"zsd05",
                "ZSL10":"zsl10",
                "ZTD05":"ztd05",
                "ZTZ99A":"ztz99",
                "ACV-15 MG3":"acv15",
                "ACV-15 M2":"acv15",
                "ACV-15 25mm":"acv15",
                "PARS III MG3 RWS":"pars",
                "PARS III Mk19 RWS":"pars",
                "PARS III M2 RWS":"pars",
                "PARS III 25mm":"pars",
                "M60T":"m60t"
            },

            team1: "",

            team1Setup: "",

            showTeam1: false,

            team2: "",
            
            team2Setup: "",

            showTeam2: false
        }
    },
    computed:{

        /**
         * Returns Team 1 flag URL
         * 
         * @returns {Array<String>} containing the flages URL's for both factions
         */
        team1Icon(){

            if (this.team1 == "") {
                return '/utils/flags/neutral.png'
            }

            return '/utils/flags/' + this.team1 + '.png'
        },

        /**
         * Returns Team 2 flag URL
         * 
         * @returns {Array<String>} containing the flages URL's for both factions
         */
        team2Icon(){

            if (this.team2 == "") {
                return '/utils/flags/neutral.png'
            }

            return '/utils/flags/' + this.team2 + '.png'
        },

        availableTeam1(){
            let ret = []

            for (const faction in this.layerInfos.factions) {
                if (this.team2Faction == "") {
                    //console.log("no team 2");
                    ret.push(faction)
                }
                else if((factions[faction].alliance != this.team2Faction.alliance && faction != this.team2Faction.shortName) || (factions[faction].alliance == "independant" && faction != this.team2Faction.shortName)){
                    //console.log(this.team2Faction.alliance + " != " + factions[faction].alliance);
                    let canAtk = false
                    for (const setup of this.layerInfos.factions[faction]) {
                        if (setup.team == 0 || setup.team == 1) {
                            canAtk = true
                        }
                    }

                    if(canAtk){
                        ret.push(faction)
                    }
                }
            }

            return ret
        },

        availableTeam1Setups(){
            let ret = []

            if (this.team1 == "") {
                return
            }

            for (const setup of this.layerInfos.factions[this.team1]) {
                if (setup.team == 1 || setup.team == 0) {
                    ret.push(setup.setup)
                }
            }

            return ret
        },

        availableTeam2Setups(){
            let ret = []

            if (this.team2 == "") {
                return
            }

            for (const setup of this.layerInfos.factions[this.team2]) {
                if (setup.team == 2 || setup.team == 0) {
                    ret.push(setup.setup)
                }
            }

            return ret
        },

        availableTeam2(){
            let ret = []

            for (const faction in this.layerInfos.factions) {
                if (this.team1Faction == "") {
                    ret.push(faction)
                }
                else if((factions[faction].alliance != this.team1Faction.alliance && faction != this.team1Faction.shortName) || (factions[faction].alliance == "independant" && faction != this.team1Faction.shortName)){
                    let canDef = false
                    for (const setup of this.layerInfos.factions[faction]) {
                        if (setup.team == 0 || setup.team == 2) {
                            canDef = true
                        }
                    }

                    if(canDef){
                        ret.push(faction)
                    }
                }
            }

            return ret
        },

        team1Faction(){
            if (factions[this.team1] == undefined) {
                return {name: "Team 1"}
            }

            return factions[this.team1]
        },

        team2Faction(){
            if (factions[this.team2] == undefined) {
                return {name: "Team 2"}
            }
            
            return factions[this.team2]
        },

        team1SetupObject(){
            if (units[this.team1Setup][this.team1] == undefined) {
                return 
            }

            let setupKey = ""

            if (this.layerInfos.size == "Large") {
                setupKey = "LgOff"
            }
            else if (this.layerInfos.size == "Medium") {
                setupKey = "MdOff"
            }
            else{
                setupKey = this.layerInfos.size
            }

            console.log(units[this.team1Setup][this.team1].setups[setupKey]);

            return units[this.team1Setup][this.team1].setups[setupKey]
        },

        team1SetupName(){
            return units[this.team1Setup][this.team1].name
        },

        team2SetupObject(){
            if (units[this.team2Setup][this.team2] == undefined) {
                return 
            }

            let setupKey = ""

            if (this.layerInfos.size == "Large") {
                setupKey = "Lg"
            }
            else if (this.layerInfos.size == "Medium") {
                setupKey = "Md"
            }
            else{
                setupKey = this.layerInfos.size
            }

            if (this.layerInfos.gamemode == "Invasion" || this.layerInfos.gamemode == "Insurgency" || this.layerInfos.gamemode == "Destruction") {
                setupKey += "Def"
            }
            else{
                setupKey += "Off"
            }

            console.log(units[this.team2Setup][this.team2].setups[setupKey]);

            return units[this.team2Setup][this.team2].setups[setupKey]
        },

        team2SetupName(){
            return units[this.team2Setup][this.team2].name
        }

    },
    watch:{
        layerInfos(){
            this.team1 = ""
            this.team2 = ""

            this.showTeam1 = false
            this.showTeam2 = false

            this.team1Setup = ""
            this.team2Setup = ""

            console.log(this.layerInfos);
        }
    },
    props:{

        /**
         * SquadLanes's URL name for the selected map, used to give the link on RAAS and Invasion layers
         * @type {String}
         */
        mapNameLanes:{
            type: String,
            required: true
        },

        /**
         * Selected layer informations
         * @type {Object}
         */
        layerInfos:{
            type: Object,
            required: true
        }
    },
    mounted(){
        console.log(this.layerInfos);
    },
    methods:{

        /**
         * Returns the URL to given vehicle icon type
         * 
         * @param {String} vhl selected vehicle
         * @returns {String} URL to the icon
         */
        getVehicleIcon(vhl){
            return '/utils/vehicles/' + vhl + '.png'
        },

        /**
         * Notify parent to display the weak spots from the selected vehicle
         * 
         * @param {String} vhl Selected vehicle
         */
        showWeakSpots(vhl){
            this.$emit('showWeakSpots', vhl, this.weakSpots[vhl])
        },

        /**
         * Returns the URL to Squad Lanes with mapNameLanes prop and current layer informations
         * 
         * @returns {String} URL to Squad Lanes's website
         */
        getSquadLanesURL(){
            return 'https://squadlanes.com/#map=' + this.mapNameLanes + '&layer=' + this.layerInfos.gamemode + '+v' + this.layerInfos.version
        },

        /**
         * Checks if layer is either RAAS or Invasion
         * 
         * @returns {boolean} True if so, False otherwise
         */
        isLanes(){
            if (this.layerInfos.gamemode.includes('RAAS') || this.layerInfos.gamemode.includes('Inv')) {
                return true
            }

            return false
        },

        /**
         * Notify parent to display the remaining time to respawn on timer vue component of given vehicle and delay
         * 
         * @param {String} vhl Selected vehicle name
         * @param {number} delay in minutes to respawn
         */
        addTimer(vhl, delay){
            this.$emit('addTimer', {vhl:vhl,delay:delay})
        },

        team1Change(){
            this.team1Setup = ""
        },

        toggleTeam1(){
            if (this.team1 != "") {
                this.showTeam1 = !this.showTeam1
            }
        },

        team2Change(){
            this.team2Setup = ""
        },
        
        toggleTeam2(){
            if (this.team2 != "") {
                this.showTeam2 = !this.showTeam2
            }
        }
    }
}
</script>

<style scoped>

#right-menu{
  background-color: rgba(20, 20, 20, 0.6);
  backdrop-filter: blur(20px);
  border-bottom: solid 3px #908F8E;
  border-top: solid 3px #908F8E;
  border-left: solid 3px #908F8E;
  position: absolute;
  max-height: 80vh;
  max-width: 30vw;
  width: 30vw;
  right: 0;
  z-index: 50;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#right-menu::-webkit-scrollbar{
  display: none;
}

#blufor{
    color: white;
    background-color: rgba(0, 0, 255, 0.5);
}

#redfor{
    color: white;
    background-color: rgba(255, 0, 0, 0.5);
}

.faction-selection{
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.faction-selection-selects{
    width: 100%;
    flex: 0 1 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem;
}

.faction-selection-selects>*{
    margin: 0 1vh;
}

.faction-selection-selects>label{
    display: flex;
    align-items: center;
}

table{
    border-collapse: collapse;
    width: 100%;
}

table img{
  max-height: 2rem;
  max-width: 2rem;
  height: auto;
  width: auto;
}

tr{
    border: 1px black solid;
    border-color: black;
}

th, td{
    margin: 0;
    padding: 0.5rem;
}
.icon-name{
    text-align: left;
}

a{
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.timer-icon{
    cursor: pointer;
}

.dropdown-enter-from, .dropdown-leave-to{
    transform: scaleY(0);
}

.dropdown-btn{
    font-size: 3vh;
    transition: all 0.3s;
}

.dropdown-btn.dropdown-active{
    transform: rotate(180deg);
}

.right-expandable{
    display: flex;
    flex-direction: column;
    padding: 1vh 0;
    align-items: center;
    margin-bottom: 2vh;
}

.right-expandable>*{
    transition: transform 0.3s ease-in-out;
    transform-origin: top;
    width: 90%;
}

.flexbox-row{
    flex: 1 1 90%;
    width: 90%;
    height: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flexbox-row>label{
    display: flex;
    align-items: center;
    margin-right: 1vh;
}

.faction-header{
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.faction-flag{
    max-height: 3vh;
    width: auto;
    margin-right: 1vh;
}

.two-columns-flexbox{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.two-columns-flexbox>div{
    flex: 0 0 30%;
    margin: 0 1vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

</style>