<template>
    <div id="mobile-map-settings">
        <button id="mobile-map-settings-close-button" @click="closeMobileMapSettings">X</button>
        <div>Map settings :</div>
        <div style="display: flex; flex-direction: row; justify-content: space-around;">   
            <div><input type="checkbox" name="showGrid" v-model="showGrid"><label for="showGrid">Grid</label></div>
            <div><input type="checkbox" name="showObjectives" v-model="showObjectives"><label for="showObjectives">Objectives</label></div>
            <div><input type="checkbox" name="showMortar" v-model="showMortar"><label for="showMortar">Mortar</label></div>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{

            /**
             * Variable used to show or hide grid on the map
             * @type {Boolean}
             */
            showGrid: true,
            
            /**
             * Variable used to show or hide objectives on the map
             * @type {Boolean}
             */
            showObjectives: true,
            
            /**
             * Variable used to show or hide mortar on the map
             * @type {Boolean}
             */
            showMortar: true
        }
    },
    methods:{

        /**
         * Notify parent to close the menu
         */
        closeMobileMapSettings(){
            this.$emit('closeMobileMapSettings')
        }
    },
    watch:{

        /**
         * On variable update, notify parent to toggle the grid display on map
         */
        showGrid(){
            this.$emit('toggleGrid')
        },
        
        /**
         * On variable update, notify parent to toggle the objectives display on map
         */
        showObjectives(){
            this.$emit('toggleObjectives')
        },
        
        /**
         * On variable update, notify parent to toggle the mortar display on map
         */
        showMortar(){
            this.$emit('toggleMortar')
        }
    }
}
</script>

<style>

#mobile-map-settings{
    background-color: black;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 15vh;
    z-index: 10000;
    display: flex;
    flex-direction: column;
}

#mobile-map-settings>*{
    padding: 2vh;
}

#mobile-map-settings-close-button{
    position: absolute;
    top: 0;
    right: 0;
    width: 10vw;
    height: 10vw;
    padding: 1vh;
    background-color: transparent;
    backdrop-filter: none;
}
</style>