export class HeightCalc{

    img;
    canvas;
    ctx;
    zStep;

    constructor(selectedMap, zStep){
        this.canvas = document.createElement('canvas')
        this.canvas.width = 500
        this.canvas.height = 500

        this.ctx = this.canvas.getContext('2d')

        this.img = new Image(500,500)
        this.img.src = '/maps/' + selectedMap + '/heightmap.jpg'

        this.img.onload = () => {
            this.ctx.drawImage(this.img, 0, 0)
        }

        this.zStep = zStep
    }

    getHeightDifference(mortarCoords, targetCoords){

        var mortarHeightmapCoords = {
            lat: (Math.abs(mortarCoords.lat - 4096) / 4096) * 500,
            lng: (mortarCoords.lng / 4096) * 500
        }

        var targetHeighmapCoords = {
            lat: (Math.abs(targetCoords.lat - 4096) / 4096) * 500,
            lng: (targetCoords.lng / 4096) * 500
        }
        
        var mortarHeightData = this.ctx.getImageData(mortarHeightmapCoords.lng, mortarHeightmapCoords.lat, 1, 1).data
        var targetHeightData = this.ctx.getImageData(targetHeighmapCoords.lng, targetHeighmapCoords.lat, 1, 1).data

        var mortarHeight = (mortarHeightData[0] + (255 - mortarHeightData[2])) * this.zStep
        var targetHeight = (targetHeightData[0] + (255 - targetHeightData[2])) * this.zStep

        return (targetHeight - mortarHeight)
    }
}