<template>
    <div id="weak-spots" class="full-center">
        <div id="title">{{ this.vehicle }}</div>
        <button id="close-button" @click="closeWeakSpots">Close</button>
        <div id="images-container">
            <div id="rack">
                <div class="title-div">Engine and ammo rack</div>
                <div class="images-div">
                    <img class="rotating-image" :src="rackSrc" :alt="this.vehicle">
                    <button class="button left" @click="this.angle+=45">&#10094;</button>
                    <button class="button right" @click="this.angle-=45">&#10095;</button>
                </div>
            </div>
            <div id="armor">
                <div class="title-div">Armor</div>
                <div class="images-div">
                    <img style="flex: 1 1; max-height: 10%; max-width: 60%; object-fit: scale-down; justify-self: center;" :src="armorSrcLegend" alt="Armor">
                    <img class="rotating-image" style="max-height: 80%;" :src="armorSrc" :alt="this.vehicle">
                    <button class="button left" @click="this.armorAngle+=90">&#10094;</button>
                    <button class="button right" @click="this.armorAngle-=90">&#10095;</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    data(){
        return{

            /**
             * The racks image angle shown in the modal
             * @type {integer}
             */
            angle: 0,

            /**
             * The armor image angle shown in the modal
             * @type {integer}
             */
            armorAngle: 0,

            /**
             * Selected vehicle name
             * @type {String}
             */
            vehicle: "",

            /**
             * Specifically named folder corresponding to the vehicle, containing images
             * @type {String}
             */
            vehicleFolder: ""
        }
    },
    methods:{

        /**
         * Notify parent to close the modal, resets angles to 0
         */
        closeWeakSpots(){
            this.angle = 0
            this.armorAngle = 0
            this.$emit('closeWeakSpots')
        }
    },
    watch:{

        /**
         * On angle update checking if it's not over 360 or below 0 to avoid errors
         */
        angle(){
            this.angle %= 360
            if (this.angle < 0) {
                this.angle += 360
            }
        },

        /**
         * On armor angle update checking if it's not over 360 or below 0 to avoid errors
         */
        armorAngle(){
            this.armorAngle %= 360
            if (this.armorAngle < 0) {
                this.armorAngle += 360
            }
        }
    },
    computed:{

        /**
         * Gives the URI to the image corresponding to the selected vehicle and the selected angle
         * @returns {String} : URI to rack image
         */
        rackSrc(){
            return '/utils/weak_spots/' + this.vehicleFolder + '/' + this.angle + '.jpg'
        },

        /**
         * Gives the URI to the image corresponding to the selected vehicle and the selected armorAngle
         * @returns {String} : URI to armor image
         */
        armorSrc(){
            return '/utils/weak_spots/' + this.vehicleFolder + '/a' + this.armorAngle + '.jpg'
        },

        /**
         * Gives the URI to the image describing the thickness, needed to understand the armor image
         * @returns {String} : URI to armor legend image
         */
        armorSrcLegend(){
            return '/utils/weak_spots/' + this.vehicleFolder + '/armor.jpg'
        }
    }
}

</script>

<style scoped>
#close-button{
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 2rem;
}

#weak-spots{
  text-align: center;
  z-index: 10000;
  position: absolute;
  width: 90vw;
  padding: 1rem;
  height: 60vh;
  background-color: rgba(20, 20, 20, 0.6);
  backdrop-filter: blur(20px);
  border: solid 3px #908F8E;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#title{
    font-size: 5vh;
    flex: 1 1;
    margin: 0;
    padding: 0;
    max-height: 10%;
    min-height: 0;
}

#images-container{
    flex: 9 9;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 90%;
    min-height: 0;
}

#armor, #rack{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.title-div{
    font-size: 1.5rem;
    flex: 0 1;
}

.images-div{
    flex: 1 1;
    max-height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rotating-image{
    flex: 1 1;
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
    object-fit: scale-down;
}

.button{
    font-size: 3vh;
    margin: 0 0.5rem;
    width: 5vh;
    height: 5vh;
}

.left{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
}

.right{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
}

</style>